import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Layout, LayoutItem, Select, Text, TextField } from "@audi/audi-ui-react";
import { api } from "../../../axios";
import qs from "qs";
import { useDispatch } from "react-redux";
import { handleResponse } from "../../../common/utils";
import "./Registration.css";
import { useHistory } from "react-router";
import ChecklistItem from "../../../common/ChecklistItem.js";
import { options } from "../../../common/interestsOptions.js";

export function Registration() {
    const data = JSON.parse(document.querySelector("#root").dataset.registrationData.replace(/'/g, "\""));
    const langLvl = [
        { id: "2", name: "Alapfok" },
        { id: "3", name: "Középfok" },
        { id: "4", name: "Felsőfok" },
    ];
    const dispatch = useDispatch();
    const history = useHistory();

    const [fullname, setFullname] = useState("");
    const [neptunCode, setNeptunCode] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [accepted, setAccepted] = useState(false);
    const [university, setUniversity] = useState("0");
    const [otherUni, setOtherUni] = useState("");
    const [degree, setDegree] = useState("0");
    const [otherDegree, setOtherDegree] = useState("");
    const [fullTime, setFullTime] = useState("0");
    const [bscOrMsc, setBscOrMsc] = useState("0");
    const [msc, setMsc] = useState("0");
    const [start, setStart] = useState("0");
    const [end, setEnd] = useState("0");
    const [language1, setLanguage1] = useState("0");
    const [language2, setLanguage2] = useState("0");
    const [otherLanguage, setOtherLanguage] = useState("");
    const [internOpenTo, setInternOpenTo] = useState("0");
    const [timeDis, setTimeDis] = useState("0");
    const [newsletter, setNewsletter] = useState(false);
    const [personalData, setPersonalData] = useState(false);

    const [fullnameError, setFullnameError] = useState("");
    const [neptunCodeError, setNeptunCodeError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [acceptedError, setAcceptedError] = useState("");
    const [personalDataError, setPersonalDataError] = useState("");

    const [error, setError] = useState(false);
    const [errored, setErrored] = useState(false);

    const [activeItemsCount, setActiveItemsCount] = useState(0);
    const [interests, setInterests] = useState([]);

    const topRef = useRef();

    const handleRegistration = () => {
        let errored = false;

        if (fullname.length === 0) {
            setFullnameError("A név megadása kötelező!");
            errored = true;
        } else {
            setFullnameError("");
        }

        if (neptunCode.length === 0) {
            setNeptunCodeError("A Neptun kód megadása kötelező!");
            errored = true;
        } else if (neptunCode.length !== 6) {
            setNeptunCodeError("A Neptun kód pontosan 6 karakter hosszú kell legyen!");
            errored = true;
        } else {
            setNeptunCodeError("");
        }

        if (phone.length === 0) {
            setPhoneError("A telefonszám megadása kötelező!");
            errored = true;
        } else {
            setPhoneError("");
        }

        if (email.length === 0) {
            setEmailError("Az email megadása kötelező!");
            errored = true;
        } else if (email.search(/^[.+_a-z0-9-]+@([0-9a-z][0-9a-z-.]*[0-9a-z][.])+[a-z][a-z][mtgvuzfrlpo]?[oepa]?$/gi) < 0) {
            setEmailError("A megadott email nem megfelelő!");
            errored = true;
        } else {
            setEmailError("");
        }

        if (password.length === 0) {
            setPasswordError("A jelszó megadása kötelező!");
            errored = true;
        } else if (password.length < 7) {
            setPasswordError("A megadott jelszó túl rövid, kérlek legyen legalább 7 karakter!");
            errored = true;
        } else if (password !== passwordRepeat) {
            setPasswordError("A két jelszó nem egyezik meg!");
            errored = true;
        } else {
            setPasswordError("");
        }

        if (accepted === false) {
            setAcceptedError("Kérlek fogadd el a feltételeket!");
            errored = true;
        } else {
            setAcceptedError("");
        }

        if (personalData === false) {
            setPersonalDataError("Kérlek fogadd el a feltételeket!");
            errored = true;
        } else {
            setPersonalDataError("");
        }

        if (university === "0") {
            errored = true;
        }
        if (degree === "0") {
            errored = true;
        }
        if (fullTime === "0") {
            errored = true;
        }
        if (bscOrMsc === "0") {
            errored = true;
        }
        if (start === "0") {
            errored = true;
        }
        if (end === "0") {
            errored = true;
        }
        if (language1 === "0" && internOpenTo === "1") {
            errored = true;
        }
        if (language2 === "0" && internOpenTo === "1") {
            errored = true;
        }
        if (internOpenTo === "0") {
            errored = true;
        }
        if (timeDis === "0" && internOpenTo === "1") {
            errored = true;
        }

        setErrored(errored);

        if (errored) {
            setError(true);
            return;
        }

        api.post("user", qs.stringify({
            fullname,
            neptunCode: neptunCode,
            email,
            phone,
            password,
            password_re: passwordRepeat,
            policy: accepted,
            personal: personalData,
            university,
            university_other: otherUni,
            degree,
            degree_other: otherDegree,
            full_time: fullTime,
            bsc_msc: bscOrMsc,
            msc,
            start,
            end_date: end,
            language_1: language1,
            language_2: language2,
            other_lang: otherLanguage,
            interests: interests.length === 0 ? "0" : interests.map(obj => obj.id).join(","),
            open_to: internOpenTo,
            newsletter: newsletter,
            time_dis: timeDis,
        }))
            .then(res => handleResponse(dispatch, res));
    };

    useEffect(() => {
        if (window.location.href.indexOf("#regisztracio") === -1 || !topRef.current)
            return;

        history.replace("/");

        topRef.current.scrollIntoView();
    });

    const items = options.map((item) => {
        return (
            <>
                <ChecklistItem
                    key={item.id}
                    setActiveItemsCount={setActiveItemsCount}
                    activeItemsCount={activeItemsCount}
                    label={item.label}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setInterests([
                                ...interests,
                                {
                                    id: item.id,
                                },
                            ]);
                        } else {
                            setInterests(
                                interests.filter((interest) => interest.id !== item.id),
                            );
                        }
                    }}
                />
            </>
        );
    });

    return (
        <Layout ref={topRef} className="registration" direction={{xs: "column", m: "row"}}>
            <LayoutItem basis={{xs: "0px", m: "300px"}} grow="1" className="left" align={{xs: "center", m: "start"}}>
                <Layout justify="center">
                    <Text variant="order2" className="registration-title">
                        Regisztráció
                    </Text>
                </Layout>
            </LayoutItem>
            <LayoutItem basis={{xs: "0px", m: "300px"}} grow="1" className="middle">
                <TextField
                    value={fullname}
                    onChange={e => setFullname(e.target.value)}
                    spacing={["l"]}
                    inputId="registration-fullname"
                    label="Teljes név *"
                    required
                    invalid={fullnameError.length > 0}
                    validationMessage={fullnameError}/>
                <TextField
                    value={neptunCode}
                    onChange={(e) => setNeptunCode(e.target.value)}
                    spacing={["l"]}
                    inputId="registration-neptun"
                    label="Neptun kód *"
                    required
                    invalid={neptunCodeError.length > 0}
                    validationMessage={neptunCodeError}
                />
                <TextField
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    spacing={["l"]}
                    inputId="registration-email"
                    label="Email cím *"
                    required
                    invalid={emailError.length > 0}
                    validationMessage={emailError}/>
                <TextField
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    spacing={["l"]}
                    inputId="registration-phone"
                    label="Telefonszám *"
                    required
                    invalid={phoneError.length > 0}
                    validationMessage={phoneError}
                />
                <TextField
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    spacing={["l"]}
                    inputId="registration-password"
                    label="Jelszó *"
                    type="password"
                    required
                    invalid={passwordError.length > 0}
                    validationMessage={passwordError}/>
                <TextField
                    value={passwordRepeat}
                    onChange={e => setPasswordRepeat(e.target.value)}
                    spacing={["l"]}
                    inputId="registration-password-repeat"
                    label="Jelszó még egyszer *"
                    type="password"
                    required
                    invalid={passwordError.length > 0}
                    validationMessage={passwordError}/>
                <div className={`intern-form`}>
                    <Text spacing={["s"]} variant="order3">Tanulmányok</Text>
                    <Select
                        spacing={["l"]}
                        inputId="intern-university"
                        label="Melyik egyetemre/főiskolára jársz? *"
                        value={university}
                        onChange={e => setUniversity(e.target.value)}
                        required
                        invalid={error && university === "0"}>
                        <option value="0">Kérünk válassz az opciók közül.</option>
                        {
                            data.universities.map((university, i) => (
                                <option key={i} value={university.id}>{university.name}</option>
                            ))
                        }
                        <option value="-1">Nincs a listában</option>
                    </Select>
                    {university === "-1" ? <TextField
                        value={otherUni}
                        onChange={e => setOtherUni(e.target.value)}
                        spacing={["l"]}
                        inputId="intern-other-uni"
                        label="Írd le az egyetem nevét *"
                        type="text"
                        required/> : null}
                    <Select
                        spacing={["l"]}
                        inputId="intern-degree"
                        label="Milyen szakon vagy? *"
                        value={degree}
                        onChange={e => setDegree(e.target.value)}
                        required
                        invalid={error && degree === "0"}>
                        <option value="0">Kérünk válassz az opciók közül.</option>
                        {
                            data.degrees.map((degree, i) => (
                                <option key={i} value={degree.id}>{degree.name}</option>
                            ))
                        }
                        <option value="-1">Nincs a listában</option>
                    </Select>
                    {degree === "-1" ? <TextField
                        value={otherDegree}
                        onChange={e => setOtherDegree(e.target.value)}
                        spacing={["l"]}
                        inputId="intern-other-degree"
                        label="Írd le a szakod nevét *"
                        type="text"
                        required/> : null}
                    <Select
                        spacing={["l"]}
                        inputId="intern-degree"
                        label="Rendelkezel-e nappali tagozatos hallgatói jogviszonnyal? *"
                        value={fullTime}
                        onChange={e => setFullTime(e.target.value)}
                        required
                        invalid={error && fullTime === "0"}>
                        <option value="0">Kérünk válassz az opciók közül.</option>
                        <option value="1">Igen</option>
                        <option value="2">Nem (levelező, távoktatás)</option>
                    </Select>
                    <Select
                        spacing={["l"]}
                        inputId="intern-degree"
                        label="BSc vagy MSc tanulmányokat folytatsz? *"
                        value={bscOrMsc}
                        onChange={e => setBscOrMsc(e.target.value)}
                        required
                        invalid={error && bscOrMsc === "0"}>
                        <option value="0">Kérünk válassz az opciók közül.</option>
                        <option value="1">BSc</option>
                        <option value="2">MSc/MA</option>
                    </Select>
                    {bscOrMsc === "1" ? <Select
                        spacing={["l"]}
                        inputId="intern-degree"
                        label="Tervezel MSc/MA képzésen tovább tanulni? *"
                        value={msc}
                        onChange={e => setMsc(e.target.value)}
                        required
                        invalid={error && msc === "0"}>
                        <option value="0">Kérünk válassz az opciók közül.</option>
                        <option value="1">Igen</option>
                        <option value="2">Nem</option>
                    </Select> : null}
                    <Select
                        spacing={["l"]}
                        inputId="intern-semesters"
                        label="Tanulmányaid kezdete *"
                        value={start}
                        onChange={e => setStart(e.target.value)}
                        required
                        invalid={error && start === "0"}>
                        <option value="0">Kérünk válassz az opciók közül.</option>
                        <option value="2017/1">2017/1</option>
                        <option value="2017/2">2017/2</option>
                        <option value="2018/1">2018/1</option>
                        <option value="2018/2">2018/2</option>
                        <option value="2019/1">2019/1</option>
                        <option value="2019/2">2019/2</option>
                        <option value="2020/1">2020/1</option>
                        <option value="2020/2">2020/2</option>
                        <option value="2021/1">2021/1</option>
                        <option value="2021/2">2021/2</option>
                        <option value="2022/1">2022/1</option>
                        <option value="2022/2">2022/2</option>
                        <option value="2023/1">2023/1</option>
                    </Select>
                    <Select
                        spacing={["l"]}
                        inputId="intern-semesters"
                        label="Várható befejezés időpontja *"
                        value={end}
                        onChange={e => setEnd(e.target.value)}
                        required
                        invalid={error && end === "0"}>
                        <option value="0">Kérünk válassz az opciók közül.</option>
                        <option value="2023/1">2023/1</option>
                        <option value="2023/2">2023/2</option>
                        <option value="2024/1">2024/1</option>
                        <option value="2024/2">2024/2</option>
                        <option value="2025/1">2025/1</option>
                        <option value="2025/2">2025/2</option>
                        <option value="2026/1">2026/1</option>
                        <option value="2026/2">2026/2</option>
                        <option value="2027/1">2027/1</option>
                        <option value="2027/2">2027/2</option>
                        <option value="2028/1">2028/1</option>
                        <option value="2028/2">2028/2</option>
                    </Select>

                    <Text spacing={["s"]} variant="order3">Karrier</Text>
                    <Select
                        spacing={["l"]}
                        inputId="intern-opento"
                        label="Nyitott vagyok gyakornoki lehetőségekre az Audi Hungariánál *"
                        value={internOpenTo}
                        onChange={e => setInternOpenTo(e.target.value)}
                        required
                        invalid={error && internOpenTo === "0"}>
                        <option value="0">Kérünk válassz az opciók közül.</option>
                        <option value="1">Igen</option>
                        <option value="2">Nem</option>
                    </Select>
                    {internOpenTo === "1" ? <><Select
                        spacing={["l"]}
                        inputId="intern-time-dis"
                        label="Milyen időtávra vállalnál gyakorlatot? *"
                        value={timeDis}
                        onChange={e => setTimeDis(e.target.value)}
                        required
                        invalid={error && timeDis === "0" && internOpenTo !== "2"}>
                        <option value="0">Kérünk válassz az opciók közül.</option>
                        <option value="1">6 hónapnál kevesebb</option>
                        <option value="2">6 hónapnál több</option>
                    </Select>
                        <div className={"intern-interests"}>
                        <label className={"sc-fzoyTs iunhzW lb"}>Érdeklődési területek (maximum 3 választható) *</label>
                        {items}
                    </div>
                    <Text spacing={["s"]} variant="order3">Nyelvtudás</Text>
                    <Select
                        spacing={["l"]}
                        inputId="intern-language1"
                        label="Német nyelvtudásod szintje *"
                        value={language1}
                        onChange={e => setLanguage1(e.target.value)}
                        required
                        invalid={error && language1 === "0" && internOpenTo !== "2"}>
                        <option value="0">Kérünk válassz az opciók közül.</option>
                        <option value="1">Nincs</option>
                        {
                            langLvl.map((language, i) => (
                                <option key={i} value={language.id}>{language.name}</option>
                            ))
                        }
                    </Select>
                    <Select
                        spacing={["l"]}
                        inputId="intern-language1"
                        label="Angol nyelvtudásod szintje *"
                        value={language2}
                        onChange={e => setLanguage2(e.target.value)}
                        required
                        invalid={error && language2 === "0" && internOpenTo !== "2"}>
                        <option value="0">Kérünk válassz az opciók közül.</option>
                        <option value="1">Nincs</option>
                        {
                            langLvl.map((language, i) => (
                                <option key={i} value={language.id}>{language.name}</option>
                            ))
                        }
                    </Select>
                    <TextField
                        className={"intern-other-lang"}
                        spacing={["l"]}
                        inputId="intern-other-lang"
                        label="Egyéb nyelvismeret"
                        value={otherLanguage}
                        onChange={e => setOtherLanguage(e.target.value)}>
                    </TextField>
                    </> : null}
                </div>
                <Text variant="copy1" spacing={["s"]}>
                    A díjazottak kihirdetése előtt az Audi Hungária bekéri a díjazásra jelöltek hallgatói jogviszonyigazolását
                    a jogosság megállapításához illetve egyéb adójogi és compliance szempontból kezelt adatokat, mint pl.
                    név, adóazonosító jel és adott esetben az esetleges érdekkonfliktusok kivizsgálásához szükséges további személyes adatokat.
                </Text>
                <Checkbox
                    checked={accepted}
                    onChange={() => setAccepted(!accepted)}
                    spacing={["l"]}
                    inputId="registration-accepted"
                    required
                    invalid={acceptedError.length > 0}
                    validationMessage={acceptedError}>
                    Elfogadom a Plan@Tech 2023 részvételi és ahhoz kapcsolódó nyereményjáték szabályzatát valamint tudomásul veszem az
                    <a href={"/data/2023/Audi_Plantech_Challenge_Dokumentumai_2023.pdf"} target={"_blank"} rel={"noreferrer"}> adatvédelmi tájékoztató</a> tartalmát.*
                </Checkbox>
                {
                    accepted ?
                            <Text variant="copy1" spacing={["s"]}>
                                Amennyiben a nyereményjátékban nem kívánsz részt venni, jelezd ezt nekünk külön e-mailben a <a
                                href={"mailto:support@plantechchallenge.hu"}>support@plantechchallenge.hu</a> címen.
                            </Text>
                            : null
                }
                <Checkbox
                    checked={personalData}
                    onChange={() => setPersonalData(!personalData)}
                    spacing={["l"]}
                    inputId="registration-personal-data"
                    required
                    invalid={personalDataError.length > 0}
                    validationMessage={personalDataError}
                    >
                    Hozzájárulok személyes adataim <a href={"/data/2023/Audi_Plantech_Challenge_Dokumentumai_2023.pdf"} target={"_blank"} rel={"noreferrer"}>
                    adatvédelmi tájékoztatónak</a> megfelelő kezeléséhez.*
                </Checkbox>
                <Checkbox
                    checked={newsletter}
                    onChange={() => setNewsletter(!newsletter)}
                    className={"registration-letter"}
                    spacing={["l"]}
                    inputId="registration-letter"
                >
                    Hozzájárulok, hogy az Audi karrier céljából megkeressen.
                </Checkbox>
                <Layout justify="center">
                    <Button
                        spacing={["l"]}
                        variant="primary"
                        onClick={handleRegistration}>
                        Regisztráció
                    </Button>
                </Layout>
                {errored ? <Text className={"error"}>Van adat, amit nem adtál meg, ellenőrizd az űrlapot!</Text> : null}
            </LayoutItem>
            <LayoutItem
                basis={{xs: "0px", m: "300px"}}
                grow="1"
                className="right"/>
        </Layout>
    );
}