import {setAlert} from "../redux/alert";
import {AlertTypes} from "../Components/Alert/Alert";


export const handleResponse = (dispatch, res) => {
    let timeout = 0;
    if (res.data.msg) {
        dispatch(setAlert({
            type: res.data.isValid ? AlertTypes.SUCCESS : AlertTypes.DANGER,
            msg: res.data.msg,
        }));
        timeout = 3000;
    }
    setTimeout(() => {
        if (res.data.redirect)
            window.location = res.data.redirect;
    }, timeout);
};