import React, { useEffect, useState } from "react";
import "./Game.css";
import { api, testHash } from "../../axios";
import { useDispatch } from "react-redux";
import { handleResponse } from "../../common/utils";
import {
    Button,
    Divider,
    Layout,
    LayoutItem,
    Loader,
    RadioButton,
    Text,
} from "@audi/audi-ui-react";
import { GameHeader } from "./GameHeader/GameHeader";
import qs from "qs";
import { setAlert } from "../../redux/alert";
import { AlertTypes } from "../../Components/Alert/Alert";
import { useHistory } from "react-router";
import { setModal } from "../../redux/modal";

export function Game() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [fetching, setFetching] = useState(true);
    const [tasks, setTasks] = useState([]);
    const [endTime, setEndTime] = useState(null);
    const [currentTaskId, setCurrentTaskId] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [endTimeout, setEndTimeout] = useState(-1);

    const handleEnd = () => {
        if (endTimeout === -1) {
            setEndTimeout(setTimeout(() => {
                history.push("/dashboard");
            }, 1 * 60 * 1000));
        }
    };

    useEffect(() => {
        api.get("/game")
            .then(res => {
                if (!res.data.isValid) {
                    handleResponse(dispatch, res);
                    return;
                }
                setTasks(res.data.data.tasks);
                let newAnswers = [];

                for (const task of res.data.data.tasks) {
                    for (const question of task.questions) {

                        //ADD QUESTION ANSWER AND QUESTION ID TO NEWANSWERS
                        newAnswers.push(question.answers.map((answer) => ({
                            questionId: question.id,
                            answerId: answer.id,
                            value: answer.value
                        })));
                        //newAnswers.push(question.answers);

                    }
                }
                newAnswers = [].concat(...newAnswers);
                console.log('init: ',newAnswers)
                setAnswers(newAnswers);
                setEndTime(new Date(res.data.data.userGameEndDateTime));
                setFetching(false);
            });

        return () => {
            if (endTimeout !== -1)
                clearTimeout(endTimeout);
        };
    }, [setFetching, setTasks, dispatch, endTimeout]);

    if (fetching) {
        return (
            <Layout className="game" justify="center">
                <Loader />
            </Layout>
        );
    }
    const currentTask = tasks[currentTaskId];
    const currentAnswer = answers[currentTaskId];

    const handleAnswerSelect = (questionId,answerId) => {
        const copy = [...answers];
        //FILTER BY QUESTION ID
        const filtered = copy.filter((answer) => answer.questionId === questionId);
        //LOOP THROUGH FILTERED ARRAY AND SET VALUE TO FALSE
        let answerIds = [];
        for (const answer of filtered) {
            answerIds.push(answer.answerId);
        }
        //LOOP BY ANsWER ID
        for (const answerIdsElement of answerIds) {
            //FIND BY ANSWERID AND MODIFY VALUE
            const index = copy.findIndex((a) => a.answerId === answerIdsElement);
            if(answerIdsElement === answerId){
                copy[index].value = true;
            }else{
                copy[index].value = false;
            }
        }


        console.log(copy)
        // Update the answers state
        setAnswers(copy);
    };

    const handleSave = async (questionId,questionType) => {
        //GET ANSWERS BY QUESTION ID
        const questionAnswers = answers.filter((answer) => answer.questionId === questionId);

        // Find the selected true answer and get its ID
        let selectedAnswers = questionAnswers.filter(answer => answer.value === true);
        if(questionType == 1){
            selectedAnswers = selectedAnswers[0]
        }
        console.log("Selected answers:", selectedAnswers);

        const data = {
            questionId: selectedAnswers.questionId, // Assuming question IDs are 1-based
            answers: selectedAnswers.answerId, // Send the ID of the true answer in array
        };

        api.post(`/task/${currentTask.id}`, qs.stringify({ ...data, testHash }))
            .then(res => {
                if (res.data.isValid) {
                    dispatch(setAlert({
                        type: AlertTypes.SUCCESS,
                        msg: "Sikeres mentés",
                    }));
                    const newSaveDateTime = res.data.data.saveDateTime;
                    let copy = [...tasks];
                    //GET INDEX OF QUESTION ID FROM TASKS
                    const index = copy[currentTaskId].questions.findIndex((question) => question.id === questionId);
                    console.log(copy)
                    console.log(index)
                    //SET SAVE DATE TIME
                    copy[currentTaskId].questions[index].saveDateTime = newSaveDateTime;
                    console.log(copy)
                    setTasks(copy);
                } else {
                    handleResponse(dispatch, res);
                }
            });


    };

    return (
        <Layout className="game" direction="column">
            <Divider />
            <LayoutItem justify="stretch" className="header-container">
                <GameHeader
                    count={tasks.length}
                    page={currentTaskId + 1}
                    changePage={id => setCurrentTaskId(id - 1)}
                    endTime={endTime}
                    onEnded={handleEnd} />
            </LayoutItem>
            <Layout direction={{ xs: "column", m: "row" }} justify="center" align={{ xs: "center", m: "start" }}>
                <div className={`image-container content ${+currentTask.id === 17 ? "sticky" : ""} ${+currentTask.id === 18 ? "sticky" : ""}`}>
                    <img
                        className="image"
                        src={currentTask.imageUrl === null ? "https://i.insider.com/5484d9d1eab8ea3017b17e29?width=600&format=jpeg&auto=webp" : process.env.PUBLIC_URL + "/" + currentTask.imageUrl}
                        alt="Woof" />
                </div>
                <div className="task-container content">
                    <Text className="title" variant="order4">
                        <span dangerouslySetInnerHTML={{ __html: currentTask.desc }} />
                    </Text>
                    <br />
                    {
                        currentTask.questions.map((question, questionIndex) => (
                            <div key={question.id} className="question-container">
                                <Text className="title" variant="order4">
                                    <span dangerouslySetInnerHTML={{ __html: question.question }} />
                                </Text>
                                <br />
                                {question.answers.map((answer, answerIndex) => {
                                    const inputId = `task-${currentTaskId}-question-${questionIndex}-answer-${answerIndex}`;
                                    const name = `task-${currentTaskId}-question-${questionIndex}`;
                                    return (
                                        <RadioButton
                                            spacing={["s"]}
                                            key={answer.id}
                                            inputId={inputId}
                                            name={name}
                                            onChange={() => handleAnswerSelect(question.id,answer.id)}
                                            checked={answers.find((a) => a.answerId === answer.id).value}
                                        >
                                            <span dangerouslySetInnerHTML={{ __html: answer.answer }} />
                                        </RadioButton>
                                    );
                                })}
                                {currentTask.type !== "2" ? (
                                    <div className="save-button-container"> {/* Add a container div */}
                                        <Button
                                            className="save-button"
                                            variant="primary"
                                            size="small"
                                            onClick={() => handleSave(question.id,question.type)}
                                        >
                                            Mentés
                                        </Button>
                                        {(typeof question.saveDateTime !== "undefined" &&
                                            question.saveDateTime !== null) ?
                                         (
                                            <Text variant="copy2">Utoljára mentve ekkor: {new Date(question.saveDateTime).toLocaleString("hu-HU")}</Text>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                        ))
                    }
                </div>
            </Layout>
            <Layout direction="row" className="navigation-buttons" align="center">
                <Button variant="secondary" disabled={currentTaskId === 0}
                    onClick={() => setCurrentTaskId(currentTaskId - 1)}>Előző</Button>
                <LayoutItem grow="1" />
                <Button variant="secondary" disabled={currentTaskId === tasks.length - 1}
                    onClick={() => setCurrentTaskId(currentTaskId + 1)}>Következő</Button>
            </Layout>;
            <Layout direction="row" className="navigation-buttons" align="center" justify="center">
                <Button variant="primary" className="finish-button"
                    onClick={() => dispatch(setModal({ name: "confirmEnd" }))}>Befejezés</Button>
            </Layout>;
        </Layout>
    )
        ;
}
