import React from "react";
import "./ConfirmationRequired.css";
import {Button, Layout, Text} from "@audi/audi-ui-react";
import {useHistory} from "react-router";

export function ConfirmationRequired() {
    
    const history = useHistory();
    
    return (
        <Layout justify="center">
            <div className="confirmation-required">
                <Text variant="order2">Megerősítés szükséges</Text>
                <br/>
                <br/>
                <Text variant="order4">
                    A regisztrációd sikeres volt, de még meg kell erősítened.<br/>
                    <br/>
                    A megadott e-mail címedre küldtünk egy levelet, ami tartalmazza a regisztrációs linket.<br/>
                    <br/>
                    Kattints rá, hogy megerősítsd a regisztrációdat.<br/>
                    <br/>
                    Az e-mail előfordulhat, hogy csak később érkezik meg, légy türelemmel. Ha fél órán belül nem érkezne
                    meg, vedd fel velünk a kapcsolatot a <a href="mailto:support@plantechchallenge.hu" target="_blank" >support@plantechchallenge.hu</a> címen.<br/>
                    <br/>
                    Előfordulhat, hogy az e-mail a spam mappában landol, segítsd a munkánkat azzal, hogy nem spamnek
                    jelölöd ;)
                </Text>
                <br/>
                <br/>
                <Button variant="primary" onClick={() => history.push("/")}>Tovább a főoldalra</Button>
            </div>
        </Layout>
    );
}