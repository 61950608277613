import {configureStore} from "@reduxjs/toolkit";
import modalReducer from "./modal";
import alertReducer from "./alert";
import userReducer from "./user";

export default configureStore({
    reducer: {
        modal: modalReducer,
        alert: alertReducer,
        user: userReducer,
    },
});