import React, {useState} from "react";
import "./Cookie.css";
import {Button, Divider, Layout, LayoutItem, Text, Toggle} from "@audi/audi-ui-react";
import {ReactComponent as AudiLogo} from "./audi-logo.svg";
import {fbq, gtag} from "../../../tracking.js";
import {connect, useDispatch} from "react-redux";
import {clearModal} from "../../../redux/modal";

function MoreInfo({children}) {
    const [dropdownActive, setDropdownActive] = useState(false);
    
    const handleDropdown = e => {
        e.preventDefault();
        setDropdownActive(!dropdownActive);
    };
    
    return (
        <>
            <a href="/" onClick={handleDropdown}><Text variant="copy2" onClick={handleDropdown}>
                <u>További információk</u> {dropdownActive ? "˄" : "˅"}
            </Text></a>
            <Layout className={`extra ${dropdownActive ? "" : "hidden"}`} direction="column" spacing={["xxs", "s"]}>
                <Text variant="copy2">
                    {children}
                </Text>
            </Layout>
        </>
    );
    
}

function Cookie({cookieName}) {
    const dispatch = useDispatch();
    const [analytics, setAnalytics] = useState(false);
    const [marketing, setMarketing] = useState(false);
    
    const handleCookies = (analytics, marketing) => {
        if (analytics) {
            gtag("consent", "update", {
                "analytics_storage": "granted",
            });
        }
        
        if(marketing) {
            fbq('consent', 'grant');
        }
        
        document.cookie = `${cookieName}=1`;
        dispatch(clearModal());
    };
    
    const handleAcceptAll = () => {
        handleCookies(true, true);
    };
    
    const handleSave = () => {
        handleCookies(analytics, marketing);
    };
    
    
    return (
        <div className="cookie">
            <Layout justify="center" align="center">
                <AudiLogo className="audi-logo" viewBox="0 0 196 68" /> <Text variant="order4">Süti beállítások</Text>
            </Layout>
            <br />
            <Text variant="copy1">
                Amikor ellátogat egy weboldalra, az információkat tárolhat vagy gyűjthet be a
                böngészőjéről, amit az esetek többségében sütik segítségével végez. Az információk vonatkozhatnak Önre
                mint felhasználóra, a preferenciáira, az Ön által használt eszközre vagy az oldal elvárt működésének
                biztosítására. Az információ általában nem alkalmas az Ön közvetlen azonosítására, de képes Önnek
                személyre szabottabb internetélményt nyújtani. Ön dönti el, hogy engedélyezi-e meghatározott típusú
                sütik használatát. További részletekért vagy az alapértelmezett beállítások módosításához kattintson a
                különböző kategóriák fejlécére. Tudnia kell azonban, hogy néhány sütitípus blokkolása érintheti az oldal
                használatának élményét és az általunk kínált szolgáltatásokat. <br /><a
                href={"/data/2022/Audi_PlanTech_Challenge_Adatvedelmi_tajekoztato.pdf"} target={"_blank"}
                rel={"noreferrer"}>Bővebb információk</a>
            </Text>
            <Layout direction="column" align="stretch">
                <Button variant="primary" spacing={["xl", "l", "xxs", "l"]} onClick={handleAcceptAll}>
                    Összes engedélyezése
                </Button>
                <br />
                <Button variant="secondary" spacing={["xxs", "l", "m", "l"]} onClick={handleSave}>
                    Beállítások mentése
                </Button>
                <Divider spacing={["s", "xxs"]} />
                <Layout direction="row">
                    <Text variant="order4" className="no-justify">Működéshez szükséges sütik</Text>
                    <LayoutItem grow="1" />
                    <Toggle inputId="cookie-essential-cookies" disabled={true} on />
                </Layout>
                <Text variant="copy2">
                    Ezen sütik elengedhetetlenül szükségesek a weboldal működéséhez, és ezért nem
                    kapcsolhatók ki a rendszereinkben. Általában csak olyan tevékenységekre válaszul kerülnek
                    elhelyezésre, mint a szolgáltatások kérése, így például az adatvédelmi beállítások megadása,
                    bejelentkezés vagy űrlapok kitöltése. A böngészőjében beállíthatja a sütik blokkolását vagy az
                    azokra való figyelmeztetést, de abban az esetben előfordulhat, hogy az oldal meghatározott részei
                    nem működnek.
                </Text>
                <MoreInfo>
                    <b>Cookie-Accept</b> Eltárolja a felhasználó hozzájárulását a weboldalunk cookie-kezelési
                    szabályzatához. Megőrzési idő 90 nap.
                    <br />
                    <br />
                    <b>PHPSESSID</b> Eltárolja a felhasználó munkamenet azonosítóját. Megőrzési idő 30 perc.
                    <br />
                    <br />
                    <b>audiSID</b> Eltárolja a bejelentkezett felhasználó egyedi munkamenetét. Megőrzési idő 7 nap.
                </MoreInfo>
                <Divider spacing={["s", "xxs"]} />
                <Layout direction="row">
                    <Text variant="order4" className="no-justify">Statisztika készítéshez szükséges sütik</Text>
                    <LayoutItem grow="1" />
                    <Toggle inputId="cookie-essential-cookies" on={analytics}
                            onChange={() => setAnalytics(!analytics)} />
                </Layout>
                <Text variant="copy2">
                    Ezen sütik segítségével vesszük számba az oldalunkon történő látogatásokat és a
                    forgalom forrásait, hogy ily módon mérjük és javítsuk az oldalunk teljesítményét. A sütik segítenek
                    nekünk meghatározni a legnépszerűbb és legkevésbé népszerű oldalakat, és segítségükkel figyeljük a
                    látogatók mozgását az oldalon. A sütik által gyűjtött információk aggregált jellegűek, ezért
                    anonimek. Amennyiben nem engedélyezi a sütik használatát, nem értesülünk róla, hogy felkereste az
                    oldalunkat.
                </Text>
                <MoreInfo>
                    <b>_ga</b> This cookie name is asssociated with Google Universal Analytics - which is a
                    significant
                    update to Google's more commonly used analytics service. This cookie is used to distinguish
                    unique users by assigning a randomly generated number as a client identifier. It is included in
                    each page request in a site and used to calculate visitor, session and campaign data for the
                    sites analytics reports. By default it is set to expire after 2 years, although this is
                    customisable by website owners.
                    <br />
                    <br />
                    <b>_gid</b> This cookie name is asssociated with Google Universal Analytics. This appears to be
                    a new cookie and as of Spring 2017 no information is available from Google. It appears to store and
                    update a unique value for each page visited.
                </MoreInfo>
                <Divider spacing={["s", "xxs"]} />
                <Layout direction="row">
                    <Text variant="order4" className="no-justify">Marketinghez szükséges sütik</Text>
                    <LayoutItem grow="1" />
                    <Toggle inputId="cookie-essential-cookies" on={marketing}
                            onChange={() => setMarketing(!marketing)} />
                </Layout>
                <Text variant="copy2">
                    Ezen sütiket hirdetőpartnereink helyezik el az oldalunkon keresztül. A sütiket a vállalatok az
                    érdeklődési körének megfelelő profil felállítására és ahhoz illeszkedő hirdetések megjelenítésére
                    használhatják más oldalakon. A működésük során egyedileg azonosítják a böngészőt és az eszközt.
                    Amennyiben nem engedélyezi ezen sütiket, nem élvezhet célzott hirdetéseket a különböző weboldalakon.
                </Text>
                <MoreInfo>
                    <b>fbp</b> E cookie-k használatával a Facebook olyan marketingszolgáltatásokat nyújthat harmadik fél hirdetők
                    részére mint például a valós-idejű licitplatformok. További információ: https://www.facebook.com/policies/cookies/
                </MoreInfo>
            </Layout>
        </div>
    );
}

const mapStateToProps = state => ({
    cookieName: state.modal.data.cookieName,
});

export default connect(mapStateToProps, null)(Cookie);