export const options = [
    {
        id: "1",
        label: "Konstrukció, szimuláció"
    },
    {
        id: "2",
        label: "Kutatás, fejlesztés"
    },
    {
        id: "3",
        label: "Gyártástechnológia-tervezés"
    },
    {
        id: "4",
        label: "Gyártás/Termelés"
    },
    {
        id: "5",
        label: "Minőségellenőrzés"
    },
    {
        id: "6",
        label: "IT"
    },
]