import React from "react";
import "./PageLoader.css";

import {Loader} from "@audi/audi-ui-react";
import {useSelector} from "react-redux";

export function PageLoader() {
    const {fetching} = useSelector(state => state.user);
    
    return (
        <div className="page-loader">
            {fetching ?
                <Loader className="progressbar" variant="linear"/> : null}
        </div>
    );
}