import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Header} from "./Components/Header/Header.jsx";
import {Footer} from "./Components/Footer/Footer.jsx";
import {Home} from "./Pages/Home/Home";
import {ProfessionalDay} from "./Pages/ProfessionalDay/ProfessionalDay.jsx"
import {Confirmation} from "./Pages/Confirmation/Confirmation";
import {ModalHandler, registerModal} from "./Components/Modals/ModalHandler/ModalHandler";
import {Login} from "./Components/Modals/Login";
import {Layout, LayoutItem} from "@audi/audi-ui-react";
import "./App.css";
import {Alert} from "./Components/Alert/Alert";
import {PageLoader} from "./Components/PageLoader/PageLoader";
import {api} from "./axios";
import {setupUser} from "./redux/user";
import {useDispatch} from "react-redux";
import {Dashboard} from "./Pages/Dashboard/Dashboard";
import {StartGame} from "./Components/Modals/StartGame";
import {Game} from "./Pages/Game/Game";
import {ConfirmationRequired} from "./Pages/ConfirmationRequired/ConfirmationRequired";
import {MissingPage} from "./Pages/MissingPage/MissingPage";
import {ForgottenPassword} from "./Components/Modals/ForgottenPassword";
import Cookie from "./Components/Modals/Cookie/Cookie";
import {fbq, gtag} from "./tracking.js";
import {setModal} from "./redux/modal";
import {Throwback} from "./Pages/Throwback/Throwback";
import {Manufactoring} from "./Pages/Manufactoring/Manufactoring";
import {Intern} from "./Pages/Intern/Intern";
import Page from "./common/Page"
import {Contact} from "./Pages/Contact/Contact";
import {ConfirmEnd} from "./Components/Modals/ConfirmEnd";
import {SuccessfulRegistration} from "./Pages/SuccessfulRegistration/SuccessfulRegistration";

registerModal(Login, "login");
registerModal(StartGame, "startGame");
registerModal(ForgottenPassword, "forgottenPassword");
registerModal(ConfirmEnd, "confirmEnd");
registerModal(Cookie, "cookie", false);

gtag("consent", "default", {
    "ad_storage": "denied",
    "analytics_storage": "denied",
});

gtag("js", new Date());
gtag("config", "UA-1683362-70");

fbq('consent', 'revoke');
fbq('init', '2232157743738455');
fbq('track', 'PageView');

export default App;

function App() {
    const dispatch = useDispatch();
    
    const cookieName = window.location.href.indexOf("test") !== -1 ? "cookie-accept-test" : "cookie-accept";
    
    const cookies = document.cookie.split(";")
        .map(cookie => cookie.split("="))
        .reduce((cookies, current) => {
            cookies[current[0].trim()] = current[1];
            return cookies;
        }, {});
    
    if (cookies[cookieName] !== "1") {
        dispatch(setModal({name: "cookie", data: {cookieName}}));
    }
    
    api.get("/user")
        .then(res => {
            if (res.data.isValid) {
                dispatch(setupUser(res.data.data));
            } else {
                dispatch(setupUser(null));
            }
        });
    
    return (
        <Router>
            <ModalHandler/>
            <Alert/>
            <PageLoader/>
            <Layout className="app" direction="column">
                <LayoutItem align="stretch" grow="0">
                    <Header/>
                </LayoutItem>
                <LayoutItem align="stretch" grow="2" className="content">
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={(props) => (
                                <Page title="Audi Plan@Tech Challenge">
                                    <Home {...props} />
                                </Page>
                            )}
                        />
                        <Route
                            exact
                            path="/visszapillanto"
                            render={(props) => (
                                <Page title="Visszapillantó - 2022 Plan@Tech Challenge">
                                    <Throwback {...props} />
                                </Page>
                            )}
                        />
                        <Route
                            exact
                            path="/gyartastechnologia-tervezes"
                            render={(props) => (
                                <Page title="Gyártástechnológia-tervezés – Audi Hungaria /gyartastechnologia-tervezes">
                                    <Manufactoring {...props} />
                                </Page>
                            )}
                        />
                        <Route
                            exact
                            path="/gyakornoki-lehetosegek"
                                render={(props) => (
                                    <Page title="Gyakornoki lehetőségek | Audi Plan@Tech Challenge">
                                        <Intern {...props} />
                                    </Page>
                                )}
                        />
                        <Route
                            exact
                            path="/kapcsolat"
                            render={(props) => (
                                <Page title="Kapcsolat | Audi Plan@Tech Challenge">
                                    <Contact {...props} />
                                </Page>
                            )}
                        />
                        <Route
                            exact
                            path="/dashboard"
                            render={(props) => (
                                <Page title="Irányítópult | Audi Plan@Tech Challenge">
                                    <Dashboard {...props} />
                                </Page>
                            )}
                        />
                        <Route
                            exact
                            path="/szakmai_nap"
                            render={(props) => (
                                <Page title="Szakmai nap | Audi Plan@Tech Challenge">
                                    <ProfessionalDay {...props} />
                                </Page>
                            )}
                        />
                        <Route
                            exact
                            path="/game"
                            render={(props) => (
                                <Page title="Játék | Audi Plan@Tech Challenge">
                                    <Game {...props} />
                                </Page>
                            )}
                        />
                        <Route exact path="/regisztracio_megerositese">
                            <Confirmation isRegistration={true}/>
                        </Route>
                        <Route exact path="/uj_jelszo_megerositese">
                            <Confirmation isRegistration={false}/>
                        </Route>
                        <Route
                            exact
                            path="/megerosites_szukseges"
                            render={(props) => (
                                <Page title="Megerősítés | Audi Plan@Tech Challenge">
                                    <ConfirmationRequired {...props} />
                                </Page>
                            )}
                        />
                        <Route
                            exact
                            path="/sikeres_regisztracio"
                            render={(props) => (
                                <Page title="Sikeres regisztráció | Audi Plan@Tech Challenge">
                                    <SuccessfulRegistration {...props} />
                                </Page>
                            )}
                        />
                        <Route
                            exact
                            path="*"
                            render={(props) => (
                                <Page title="Az oldal nem található | Audi Plan@Tech Challenge">
                                    <MissingPage {...props} />
                                </Page>
                            )}
                        />
                    </Switch>
                </LayoutItem>
                <LayoutItem align="stretch" grow="0">
                    <Footer/>
                </LayoutItem>
            </Layout>
        </Router>
    );
}
