import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {AudiPlatformProvider} from "@audi/audi-ui-react";
import {Provider} from "react-redux";

import store from "./redux/store";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AudiPlatformProvider>
                <App/>
            </AudiPlatformProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root"),
);

reportWebVitals();
