import React from "react";
import {Button, Layout, LayoutItem, Text} from "@audi/audi-ui-react";
import {api} from "../../axios";
import {handleResponse} from "../../common/utils";
import {useDispatch} from "react-redux";

export function Confirmation({isRegistration}) {
    
    const dispatch = useDispatch();
    
    const clickHandler = () => {
        api.post(`/confirmation/${isRegistration ? "registration" : "forgottenPassword"}`, window.location.search.substr(1))
            .then(res => handleResponse(dispatch, res));
    };
    
    return (
        <Layout className="confirmation" direction="column">
            <LayoutItem spacing={["xxl"]} align="center">
                <Text variant="order2">
                    {`${isRegistration ? "Regisztráció" : "Jelszó"} megerősítése`}
                </Text>
            </LayoutItem>
            <LayoutItem spacing={["xl"]} align="center">
                <Text variant="copy1">
                    {isRegistration ?
                        "A regisztráció megerősítéséhez nyomd meg a megerősítés gombot" :
                        "A jelszó megváltoztatásához nyomd meg a megerősítés gombot"}
                </Text>
            </LayoutItem>
            <LayoutItem align="center">
                <Button variant="primary" onClick={clickHandler}>
                    Megerősítés
                </Button>
            </LayoutItem>
        </Layout>
    );
}