import React from "react";
import "./MissingPage.css";
import {Button, Layout, Text} from "@audi/audi-ui-react";
import {useHistory} from "react-router";

export function MissingPage() {
    
    const history = useHistory();
    
    return (
        <Layout justify="center">
            <div className="missing-page">
                <Text variant="order2">404</Text>
                <br/>
                <br/>
                <Text variant="order4">
                    Sajnos a keresett tartalom nem található.
                </Text>
                <br/>
                <br/>
                <Button variant="primary" onClick={() => history.push("/")}>Tovább a főoldalra</Button>
            </div>
        </Layout>
    );
}