import React from "react";
import {Button, Layout, LayoutItem, Text} from "@audi/audi-ui-react";
import {api, testHash} from "../../axios";
import {useDispatch} from "react-redux";
import {handleResponse} from "../../common/utils";
import qs from "qs";

export function StartGame() {
    const dispatch = useDispatch();
    const startHandler = () => {
        api.post("/game", qs.stringify({testHash}))
            .then(res => handleResponse(dispatch, res));
    };
    
    return (
        <Layout justify="center" direction="column">
            <Text variant="order3" weight="bold">Kedves Versenyző!</Text><br/>
            <br/>
            <Text variant="copy1">
                A következő oldalakon, a 2023-es AUDI Plan@Tech mérnökverseny online feladatsorát találod, amelyet az Audi Hungária Zrt. Gyártástervezés részlege állított össze.<br/><br/>
                A feladatok nem épülnek egymásra, elvégzésük tetszőleges sorrendben is történhet, a válaszaid utólag is módosíthatod, bármikor visszaléphetsz egy korábbi feladathoz.<br/><br/>
                Kérjük, a feladatsort önállóan oldd meg! A kitöltéshez segédeszközök (könyv, számológép, papír, Google,… stb.) igénybe vehetők.<br/><br/>
                A megoldásra 60 perc áll rendelkezésedre. Az idő letelte után nem módosíthatod válaszaidat. A 60 perc lejárta elött is bármikor beküldheted megoldásidat, de azt követően módosításra nincs lehetőség!<br/><br/>
                A feladatok között lesznek logikai feladványok, illetve szakmai (gépész, villamos, ált. mérnöki ismeretek, gyártástervezés) kérdések is. A feladatok nehézségüket tekintve különbözőek, ügyelj az időbeosztásra!<br/><br/>
                Ne felejtsd el menteni a Mentés gomb megnyomásával. Ekkor a felület visszajelez egy időbélyeggel, hogy mikor került mentésre a válasz. Illetve a felület az oldal frissítésére visszatölti a már bevitt válaszokat. Így kérünk mindig ellenőrizd, mit válaszoltál, és mi került mentésre, emiatt később reklamációt nem fogunk tudni elfogadni.<br/><br/>
                Sok sikert kívánunk!<br/>
                Audi Plan@Tech mérnökverseny szervezői
            </Text>
            <LayoutItem align="end">
                <Button variant="primary" onClick={startHandler}>Start!</Button>
            </LayoutItem>
        </Layout>
    );
}