import React from "react";
import "./Footer.css";
import {ReactComponent as InvertedLogo} from "./audi-logo-inverted.svg";
import {Layout, LayoutItem, Text} from "@audi/audi-ui-react";
import {useHistory} from "react-router";

export function Footer() {
    const history = useHistory();

    const today = new Date();
    const year = today.getFullYear();
    
    return (
        <footer className="footer">
            <Layout className="footer-layout" align="center" direction={{xs: "column", m: "row"}}>
                <LayoutItem spacing={["xxs", "s"]} align="center">
                    <InvertedLogo className="audi-logo-inverted" viewBox="0 0 196 68"/>
                </LayoutItem>
                <Text className="legal-rights" variant="copy3" spacing={["s"]}>
                    &copy; AUDI {year}. All rights reserved
                </Text>
                <LayoutItem grow="1"/>
                <LayoutItem align="center">
                    <a href="https://www.facebook.com/audikarrier/" rel="noreferrer" target="_blank">
                        <svg className="social" viewBox="6 6 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.768 15.737H12v3.315h3.768V29h4.422v-9.948h4.42v-3.315h-4.42v-1.335c0-1.002 1.053-3.087 2.589-3.087h1.831V8h-3.868c-5.457.795-4.974 7.737-4.974 7.737z"
                                fill="white"></path>
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/audikarrier/" rel="noreferrer" target="_blank">
                        <svg className="social" viewBox="8 8 20 20" xmlns="http://www.w3.org/2000/svg">
                            <g fill="white">
                                <path
                                    d="M18 10.621c2.403 0 2.688.01 3.637.053.877.04 1.354.188 1.671.31.42.162.72.359 1.035.672.316.316.51.615.672 1.035.123.318.27.795.311 1.673.043.948.052 1.233.052 3.636s-.009 2.688-.052 3.637c-.04.878-.186 1.354-.31 1.671a2.8 2.8 0 01-.674 1.035c-.315.316-.615.51-1.035.672-.315.125-.792.27-1.67.311-.949.043-1.234.052-3.637.052s-2.688-.009-3.637-.052c-.877-.04-1.354-.186-1.671-.31a2.787 2.787 0 01-1.036-.672 2.771 2.771 0 01-.671-1.036c-.125-.317-.27-.794-.311-1.67-.043-.95-.053-1.235-.053-3.638 0-2.403.01-2.688.053-3.637.04-.877.188-1.354.31-1.671.162-.42.359-.72.672-1.036.316-.315.615-.51 1.035-.671.318-.125.795-.27 1.673-.311.948-.043 1.233-.053 3.636-.053zM18 9c-2.445 0-2.751.01-3.711.054-.957.045-1.612.195-2.184.418a4.385 4.385 0 00-1.595 1.039 4.417 4.417 0 00-1.037 1.594c-.223.572-.376 1.227-.419 2.184C9.009 15.249 9 15.557 9 18c0 2.444.01 2.751.054 3.711.045.957.195 1.613.418 2.184.23.593.537 1.095 1.039 1.595a4.398 4.398 0 001.594 1.038c.572.222 1.227.375 2.184.418.96.045 1.268.054 3.711.054 2.444 0 2.751-.01 3.711-.054.957-.045 1.613-.195 2.184-.419a4.385 4.385 0 001.595-1.038 4.398 4.398 0 001.038-1.594c.222-.572.375-1.227.418-2.184.045-.96.054-1.267.054-3.711s-.01-2.751-.054-3.711c-.045-.957-.195-1.612-.419-2.184a4.412 4.412 0 00-1.038-1.595 4.41 4.41 0 00-1.594-1.037c-.572-.223-1.227-.376-2.184-.419C20.751 9.009 20.444 9 18 9z"></path>
                                <path
                                    d="M18 13.379a4.622 4.622 0 100 9.243 4.622 4.622 0 000-9.243zM18 21a3 3 0 110-6 3 3 0 010 6z"></path>
                                <circle cx="22.805" cy="13.195" r="1.08"></circle>
                            </g>
                        </svg>
                    </a>
                    <a href="https://www.youtube.com/audi" rel="noreferrer" target="_blank">
                        <svg className="social" viewBox="7 7 23 23" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M28.79 13.58s-.206-1.448-.834-2.085c-.8-.837-1.695-.84-2.104-.89-2.94-.213-7.348-.213-7.348-.213h-.009s-4.408 0-7.347.213c-.41.05-1.304.053-2.104.89-.63.637-.834 2.085-.834 2.085S8 15.276 8 16.976v1.595c0 1.698.21 3.397.21 3.397s.205 1.447.834 2.085c.8.837 1.848.81 2.316.897 1.68.162 7.14.212 7.14.212s4.413-.006 7.35-.22c.412-.049 1.306-.053 2.106-.889.63-.637.834-2.085.834-2.085s.21-1.7.21-3.399v-1.593a31.8 31.8 0 00-.21-3.398zm-12.457 6.922l-.002-5.901 5.675 2.961-5.674 2.94z"
                                fill="white"></path>
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/company/audi-hungaria" rel="noreferrer" target="_blank">
                        <svg className="social" viewBox="-1 -1 26 26" xmlns="http://www.w3.org/2000/svg">
                            <path fill="white"
                                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                        </svg>
                    </a>
                </LayoutItem>
                <Text className="contact-link" spacing={["s"]}>
                    <a href="/#" onClick={e => {
                        e.preventDefault();
                        history.push("/kapcsolat");
                    }}>
                        Kapcsolat
                    </a>
                </Text>
            </Layout>
        </footer>
    );
}