import React from "react";
import {Button, Layout, LayoutItem, Text} from "@audi/audi-ui-react";
import {useDispatch} from "react-redux";
import {clearModal} from "../../redux/modal";
import {api} from "../../axios";
import {handleResponse} from "../../common/utils";

export function ConfirmEnd() {
    const dispatch = useDispatch();
    
    const handleCancel = () => {
        dispatch(clearModal());
    };
    
    const handleExit = () => {
        api.delete("/game")
            .then(res => handleResponse(dispatch, res));
        
        dispatch(clearModal());
    };
    
    return (
        <Layout justify="center" direction="column" className="confirm-end">
            <Text variant="order3">
                Biztosan befejezed a feladatsor kitöltését?<br/><br/>
            </Text>
            <Text variant="copy1">
                A „Befejezés” gombra kattintva csak a mentett válaszaid kerülnek rögzítésre és értékelésre, ezt követően módosítás már nem lehetséges, az online játék felületre nem tudsz újra belépni!<br/><br/>
            </Text>
            <br/>
            <Layout direction="row">
                <Button variant="secondary" onClick={handleCancel}>
                    Mégse
                </Button>
                <LayoutItem grow="1" />
                <Button variant="primary" onClick={handleExit}>
                    Befejezés
                </Button>
            </Layout>
        </Layout>
    );
}