import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

export function Countdown({remainingSeconds}) {
    const {fetchEnded} = useSelector(state => state.user);
    
    const [helper, setHelper] = useState(false);
    
    const timer = () => {
        setHelper(!helper);
    };
    
    useEffect(() => {
        const handle = setTimeout(timer, 1000);
        
        return () => {
            clearTimeout(handle);
        }
    });
    
    const remaining = remainingSeconds - Math.floor((Date.now() - fetchEnded) / 1000);
    
    const days = Math.floor(remaining / 86400);
    const hour = Math.floor((remaining % 86400) / 3600);
    const minute = Math.floor((remaining % 3600) / 60);
    const second = remaining % 60;
    
    return (
        <span>
            {`${days > 0 ? (days + " nap") : ""} ${hour} óra ${minute} perc ${second} mp`}
        </span>
    );
}