import React from "react";
import "./Timer.css";
import {Layout, LayoutItem, Text} from "@audi/audi-ui-react";

const GAME_LENGTH = 3600;
const DANGER_TIME = 180;

function TimerSection({fillPercentage}) {
    return (
        <LayoutItem className="section" align="center">
            <div className="background"/>
            <div className="bar" style={{width: `${fillPercentage * 100}%`}}/>
        </LayoutItem>
    );
}

export function Timer({time}) {
    const sections =
        Array(6)
            .fill(0)
            .map((_, i) => i)
            .map(x => Math.max(Math.min(time - (GAME_LENGTH / 6) * x, GAME_LENGTH / 6), 0) / GAME_LENGTH * 6);
    
    return (
        <Layout className="timer" direction={{xs: "column", m: "row"}}>
            <Layout direction="row">
            {
                sections.map((fill, i) => (
                    <TimerSection key={i} fillPercentage={fill} />
                ))
            }
            </Layout>
            <Text variant="copy2" spacing={["s"]} className={time < DANGER_TIME ? "danger" : ""}>
                {time > 0 ? `${Math.floor(time / 60).toString().padStart(2, "0")} perc ${Math.floor(time % 60).toString().padStart(2, "0")} mp` : "Határidő lejárt"}
            </Text>
        </Layout>
    );
}