import React, { useRef, useEffect } from "react";
import "./Manufactoring.css";
import {Button, Layout, Text} from "@audi/audi-ui-react";
import {useHistory} from "react-router";

export function Manufactoring() {

    const history = useHistory();

    const videoRef = useRef();

    useEffect(() => {
        if (window.location.href.indexOf("#video") === -1 || !videoRef.current)
            return;

        history.replace("/");

        videoRef.current.scrollIntoView();

    });

    return (
        <Layout justify="center" direction="column" align="center" spacing={["xl"]}>
            <div className="manufactoring">
                <section>
                    <Text variant="order1">Gyártástechnológia-tervezés – Audi Hungaria</Text>
                    <br/>
                    <Text variant="copy1">
                        A Gyártástechnológia-tervezés a gyártási folyamatok legfontosabb alappillére. A technológiák, a folyamatok
                        és a berendezések optimális működése nélkül nem készülhetne el az a több ezer motor és autó, amelyek az Audi
                        Hungariát a világ egyik legnagyobb motorgyárává és az egyik legkorszerűbb járműgyárává teszik. Az üzem-, és
                        funkcióbiztos működés, a különféle hibák analizálása és kiküszöbölése a mindennapi munkafolyamatokhoz elengedhetetlen. <br/><br/>
                    </Text>
                    <Text variant="copy1">
                        A Gyártástervezés Járműhajtások terület komplex és izgalmas szegmense a vállaltnak, hiszen több terület közös munkáján
                        és együttműködésén alapul. A szakterület egy összekötő kapocs a fejlesztés és a termelés között. Ingolstadti társterületével
                        szorosan együttműködve gazdaságos, innovatív és jövőbe mutató járműhajtás gyártási koncepciókat fejleszt, tervez és valósít
                        meg az AUDI AG és a teljes VW Konszern számára. <br/><br/>
                    </Text>
                    <Text variant="copy1">
                        A szakterület által lebonyolított projektek az első termékötlettől a gyártási koncepció kidolgozásán és a gyártósor telepítésén
                        át egészen a gyártósor átadásig egy kézből valósulnak meg. Feladatuk, hogy biztosítsák a jelen és a jövő hajtásainak gyártási
                        rendszereit, mindezt új technológiai kihívásokkal a megbízhatóság, a költséghatékonyság, a fenntarthatóság és az innováció jegyében. <br/><br/>
                    </Text>
                    <Text variant="copy1">
                        Kihívásunkkal most te is beléphetsz a Gyártástechnológia-tervezés innovatív világába! Regisztrálj az online versenyre és jó
                        válaszaiddal nem csak az értékes nyereményeket zsebelheted be, hanem ellátogathatsz szakmai napunkra is! <br/><br/>
                    </Text>
                    <Text variant="copy1">
                        Ismerd meg munkatársaink tapasztalatait és szerezz első kézből információt komplex feladataikról, szakmai kihívásaikról,
                        munkakörnyezetükről és speciális munkaeszközeikről! <br/><br/>
                    </Text>
                    <Text variant="copy1">
                        Nyerj bepillantást videónkon keresztül az Audi Hungaria Gyártástechnológia-tervezés területébe és nézd meg mivel töltik kollégáink mindennapjaikat! <br/><br/>
                    </Text>
                </section>
                <section ref={videoRef} className="content">
                    <Layout className="content" justify="center" align="center" direction={{xs: "column", m: "row"}}>
                        <iframe
                            src="https://www.youtube.com/embed/0MTNCh0DQEc"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                    </Layout>
                </section>
                <section>
                    <Text variant="copy1">
                        <br/><br/> Kedvet kaptál? Regisztrálj az online versenyre, MOST! <br/><br/>
                    </Text>
                    <Button variant="primary"
                            onClick={() => history.push("/#regisztracio")}>Regisztrálok</Button>
                </section>
                <br/><br/>
                </div>

        </Layout>
    );
}