window.dataLayer = window.dataLayer || [];

export function gtag() {
    window.dataLayer.push(arguments);
}

const scope = {};

(function (scope, doc, elementType, src, n, t, s) {
    if (scope.fbq) return;
    n = scope.fbq = function () {
        n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!scope._fbq) scope._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = doc.createElement(elementType);
    t.async = !0;
    t.src = src;
    s = doc.getElementsByTagName(elementType)[0];
    s.parentNode.insertBefore(t, s);
})(scope, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

window.fbq = scope.fbq;

export const fbq = scope.fbq;