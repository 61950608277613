import React from "react";
import "./Throwback.css";
import {audiDarkTheme, Button, Layout, LayoutItem, Text, ThemeProvider} from "@audi/audi-ui-react";
import {useHistory} from "react-router";
import {useEffect, useRef, useState} from "react";

import throwback from "./throwback.jpg"
import {BackdropSection} from "../Home/Home.jsx";

import slider11 from "./images/slider_1_1.jpg";
import slider12 from "./images/slider_1_2.jpg";
import slider13 from "./images/slider_1_3.jpg";
import slider14 from "./images/slider_1_4.jpg";
import slider15 from "./images/slider_1_5.jpg";
import slider16 from "./images/slider_1_6.jpg";
import slider17 from "./images/slider_1_7.jpg";
import slider18 from "../Home/images/slider_1_8.jpg";

export function Throwback() {
    const slider2 = [slider11, slider12, slider13, slider14, slider15, slider16, slider17, slider18];

    const history = useHistory();

    const videoRef = useRef();

    useEffect(() => {
        if (window.location.href.indexOf("#video") === -1 || !videoRef.current)
            return;

        history.replace("/");

        videoRef.current.scrollIntoView();

    });
    
    return (
        <div className="throwback">
            <BackdropSection className="top" slider={slider2} side="left" hideImages></BackdropSection>
            <section className="description">
            <Layout justify="center" direction="row" align="center">
                <LayoutItem align="center" className="content">
                    <section>
                        <Text variant="order1">Plan@Tech Challenge 2022 – Audi Hungaria</Text>
                        <br/>
                        <Text variant="copy1">
                            Sebesség, kalandvágy, tudás. Mindezt és még sok minden mást nyújtott a 2022-es Plan@Tech Challenge
                            annak a közel 50 hallgatónak, akik kétfordulós mérnökhallgatói versenyünkön bizonyították szakmai
                            rátermettségüket. A helyszíni döntőn valós ipari problémákat oldottak meg csapatokban dolgozva,
                            egymással vetélkedve és bepillantást nyertek az Audi Hungaria gyártósorainak működésébe is. <br/><br/>
                        </Text>
                        <Text variant="copy1">
                            Tekintsd meg a tavalyi versenyen készült fotógalériánkat, valamint rendezvényfilmünket és ha kedvet
                            kaptál, regisztrálj versenyünkre az értékes nyereményekért és a szakmai élményekért!
                        </Text>
                        <br/>
                        <Button variant="primary"
                                onClick={() => history.push("/#regisztracio")}>Regisztrálok</Button> <br/><br/>
                    </section>
                    <section ref={videoRef} className="content">
                        <Layout className="content" justify="center" align="center" direction={{xs: "column", m: "row"}}>
                            <iframe
                                src="https://www.youtube.com/embed/9Bvr-a6-S2E"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                        </Layout>
                    </section>
                    <Text variant="order3"><b><br/><br/>A gokart pályán is megmutatták tehetségüket a 2022-es Plan@Tech Challenge győztesei</b></Text>
                    <section className="description">
                        <Layout direction="row" justify="center" align="center">
                            <LayoutItem align="center" className="content">
                                <img src={throwback} alt=""/>
                                <Text variant="copy1">
                                    A tavalyi évben az Audi Hungaria Gyártástechnológia-tervezés és Employer Branding csapata által szervezett Plan@Tech
                                    mérnökhallgatói verseny helyszíni fordulóján az 5 legkiválóbb teljesítményt nyújtó hallgató egy-egy élményvezetési
                                    lehetőséggel térhetett haza. A nyeremény programra egy forró nyári napon, július 19-én, a Driving Camp tesztpályáján
                                    került sor, ahol a győztesek nem mindennapi tapasztalatokkal bővíthették közutakon eddig megszerzett ismereteiket. <br/><br/>
                                </Text>
                                <Text variant="copy1">
                                    A fiatalok a délelőtt folyamán egy igencsak izgalmas vezetéstechnikai tréningen vehettek részt, ahol gyakorolhatták
                                    többek között a fékezést vészhelyzetben, a szlalomozást, a kanyarodás helyes technikáját hajtűkanyarban, sőt azt is,
                                    hogyan szerezzék vissza az uralmat sodródó járművük felett. <br/><br/>
                                </Text>
                                <Text variant="copy1">
                                    Az őszi kétfordulós verseny folytatásaként a „harmadik megmérettetés” egy gokart futam volt, ahol a
                                    fiatalok megcsillogtathatták a tréningen szerzett tudásukat. <br/><br/>
                                </Text>
                                <Text variant="copy1">
                                    A nap felejthetetlen pillanatairól egy közös autózás közben számoltak be kollégáinknak, miközben egy
                                    csodaszép Audi e-tron kényelmét és megbízhatóságát élvezhették. <br/><br/>
                                </Text>
                                <Text variant="copy1">
                                    A Plan@Tech Challenge versenynek köszönhetően a résztvevők nem csak számos emlékezetes élménnyel és új
                                    barátsággal gazdagodhattak, többen közülük azóta egy gyakornoki pozíció boldog tulajdonosai az Audi
                                    Hungariánál. Reméljük, hogy az idei verseny után is több fiatal vállalatunknál folytatja majd szakmai karrierjét! <br/><br/>
                                </Text>
                            </LayoutItem>
                        </Layout>
                    </section>
                </LayoutItem>
            </Layout>
            </section>
        </div>
    );
}