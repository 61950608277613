import {createSlice} from "@reduxjs/toolkit";

export const alertSlice = createSlice({
    name: "alert",
    initialState: {
        type: null,
        msg: null,
    },
    reducers: {
        setAlert: (state, action) => {
            state.type = action.payload.type;
            state.msg = action.payload.msg;
        },
        clearAlert: state => {
            state.type = null;
        },
    },
});

export const {setAlert, clearAlert} = alertSlice.actions;

export default alertSlice.reducer;