import React from "react";
import {useDispatch, useSelector} from "react-redux";
import "./ModalHandler.css";
import {Layout} from "@audi/audi-ui-react";
import {clearModal} from "../../../redux/modal";

let modals = {};

export function registerModal(component, name, allowExit=true) {
    modals[name] = {component, allowExit};
}

export function ModalHandler() {
    const dispatch = useDispatch();
    
    document.body.style.overflow = "auto";
    
    const {name, data} = useSelector(state => state.modal);
    if (name === null)
        return null;
    
    document.body.style.overflow = "hidden";
    
    if (typeof modals[name] === "undefined") {
        console.log("Undefined modal", name);
        return null;
    }
   
    const handleClose = () => {
        dispatch(clearModal());
    };
    
    return (
        <div className="modal-container">
            <div className="backdrop"/>
            <div className="modal">
                <Layout justify="center">
                    <div className="container">
                        {modals[name].allowExit ? <span className="cross" onClick={handleClose}>+</span> : null}
                        {React.createElement(modals[name].component, data)}
                    </div>
                </Layout>
            </div>
        </div>
    );
}
