import React from "react";
import "./SuccessfulRegistration.css";
import {Button, Layout, Text} from "@audi/audi-ui-react";
import {useHistory} from "react-router";

export function SuccessfulRegistration() {

    const history = useHistory();

    return (
        <Layout justify="center">
            <div className="successful-registration">
                <Text variant="order2">Sikeres regisztráció</Text>
                <br/>
                <br/>
                <Text variant="order4">
                    A regisztrációdat sikeresen megerősítetted, most már beléphetsz az oldalunkon.
                </Text>
                <br/>
                <br/>
                <Button variant="primary" onClick={() => history.push("/")}>Tovább a főoldalra</Button>
            </div>
        </Layout>
    );
}