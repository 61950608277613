import React, {useState} from "react";
import {Button, Layout, Text, TextField} from "@audi/audi-ui-react";
import qs from "qs";
import {handleResponse} from "../../common/utils";
import {useDispatch} from "react-redux";
import {api} from "../../axios";

export function ForgottenPassword() {

    const dispatch = useDispatch();
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    
    const sendHandler = () => {
        api.post("/forgottenPassword", qs.stringify({email, password, password_re: passwordRepeat}))
            .then(res => handleResponse(dispatch, res));
    };
    
    return (
        <Layout justify="center" direction="column">
            <Text variant="order2">Elfelejtett jelszó</Text>
            <TextField value={email} onChange={e => setEmail(e.target.value)} spacing={["s"]} label="Email"
                       inputId="login-email" type="text" maxLength={200}
                       name="login-email-field" pattern={null} required/>
            <TextField value={password} onChange={e => setPassword(e.target.value)} spacing={["s"]} label="Jelszó"
                       inputId="login-password" type="password" maxLength={9999}
                       name="login-password-field" pattern={null} required/>
            <TextField value={passwordRepeat} onChange={e => setPasswordRepeat(e.target.value)} spacing={["s"]} label="Jelszó újra"
                       inputId="login-password-re" type="password" maxLength={9999}
                       name="login-password-re-field" pattern={null} required/>
            <Button spacing={["s"]} variant="primary" onClick={sendHandler}>Elküldés</Button>
        </Layout>
    );
}