import React, {useEffect, useRef, useState} from "react";
import "./Home.css";
import {Registration} from "./Registration/Registration";
import {audiDarkTheme, Button, Layout, LayoutItem, Text, ThemeProvider} from "@audi/audi-ui-react";
import {useHistory} from "react-router";

import slider11 from "./images/slider_1_1.jpg";
import slider12 from "./images/slider_1_2.jpg";
import slider13 from "./images/slider_1_3.jpg";
import slider14 from "./images/slider_1_4.jpg";
import slider15 from "./images/slider_1_5.jpg";
import slider16 from "./images/slider_1_6.jpg";
import slider17 from "./images/slider_1_7.jpg";
import slider18 from "./images/slider_1_8.jpg";
import award_background from "./images/award_background.jpg";
import timing1 from "./images/timing_1.jpg";
import timing2 from "./images/timing_2.jpg";
import timing3 from "./images/timing_3.jpg";
import logo from "./images/plantech_logo_white.png";
import {FAQ} from "./FAQ/FAQ.jsx";

export function BackdropSection({className, slider, children, side, background, imageTitle, hideImages}) {
    const [selected, setSelected] = useState(0);
    
    useEffect(() => {
        const handle = setTimeout(() => {
            const nextIndex = (selected + 1) % slider.length;
            let image = new Image();
            image.src = slider[nextIndex];
            
            image.onload = () => setSelected(nextIndex);
        }, 3500);
        
        return () => {
            clearTimeout(handle);
        };
    });
    
    const backgroundImage = background ?? slider[selected];
    
    return (
        <section className={className}>
            <div className="backdrop" style={{backgroundImage: `url(${backgroundImage})`}}></div>
            <Layout className="content" justify="center" align="center" direction={{xs: "column", m: "row"}}>
                {side === "left" ?
                    <>
                        <div className={`image ${hideImages ? "hide" : ""}`} style={{backgroundImage: `url(${slider[selected]})`}}/>
                        <Text variant="copy2">{imageTitle ?? ""}</Text>
                    </> : null}
                <ThemeProvider theme={audiDarkTheme}>
                    <LayoutItem align="center">
                        <Layout direction="column" align={{xs: "center", m: "start"}}>
                            {children}
                        </Layout>
                    </LayoutItem>
                </ThemeProvider>
                {side === "right" ?
                    <LayoutItem direction="column" align="center">
                        <div className="image" style={{backgroundImage: `url(${slider[selected]})`}}/>
                        <Text variant="copy3" className="white">{imageTitle ?? ""}</Text>
                    </LayoutItem> : null}
            </Layout>
        </section>
    );
}

export function Home() {
    const slider1 = [slider11, slider12, slider13, slider14, slider15, slider16, slider17, slider18];

    const history = useHistory();
    
    const videoRef = useRef();

    useEffect(() => {
        if (window.location.href.indexOf("#video") === -1 || !videoRef.current)
            return;
        
        history.replace("/");
        
        videoRef.current.scrollIntoView();
        
    });
    
    return (
        <div className="home">
            <BackdropSection className="top" slider={slider1} side="left" hideImages>
                <img className="logo" src={logo} alt="Plan@Tech Challenge"/>
                <Text variant="copy1" spacing={["s", "xxs", "xxs", "xl"]}>Kapcsold mérnöki karriered <br/> magasabb sebességfokozatba!</Text>
                <Text variant="copy1" spacing={["s", "xxs", "xxs", "xl"]}>2023.10.05. – 10.09.<br/><br/></Text>
                <Button variant="primary" spaceInlineStart="xl" spaceInlineEnd="xl"
                        onClick={() => history.push("#regisztracio")}>Regisztráció</Button>
            </BackdropSection>
            <section className="description">
                <Layout direction="row" justify="center" align="center">
                    <LayoutItem align="center" className="content">
                        <Text variant="order3" spacing={["s", "xl", "xxs", "xxs"]}>
                            Magasabb sebességfokozatba kapcsol a Plan@Tech Challenge!
                        </Text>
                        <Text variant="copy1" spacing={["s", "xl", "xxs", "xxs"]}>
                            Immáron 3. alkalommal startol el az Audi Hungaria Gyártástechnológia-tervezésének szakmai versenye,
                            a Plan@Tech Challenge! Regisztrálj az online versenyre, hogy a Tiéd lehessen a 3 db 1 évre szóló
                            Netflix előfizetés, vagy akár az 5 élményvezetés egyike, sőt, meghívást kaphatsz szakmai napunkra
                            is, ahol eddig nem tapasztalt programokkal és nyereménnyel várunk!
                        </Text>
                        <Text variant="copy1" spacing={["s", "xl", "xxs", "xxs"]}>
                            Kíváncsi vagy, mit is csinál pontosan a Gyártástechnológia-tervezés? Szívesen megismernéd, melyek a
                            jövőbeni gyártási technológiáik? Bepillantanál tervezőmérnök csapatunk működésébe?
                            Ha aktív, nappali tagozatos mérnök hallgató vagy és el tudod képzelni, hogy a jövőben a világ
                            legnagyobb motorgyárának mérnöki gárdáját erősítsd, akkor ne habozz, regisztrálj!
                        </Text>
                        <Text variant="copy1" spacing={["s", "xl", "xxs", "xxs"]}>
                            <b>Mit nyersz, ha részt veszel a kihívásban?</b>
                        </Text>
                        <ul>
                            <li>
                                <Text variant="copy1">
                                    A verseny feladatsort kitöltők között kisorsolunk <b>3 db 1 éves Netflix előfizetés</b>t. *
                                </Text>
                            </li>
                            <li>
                                <Text variant="copy1">
                                    Az 5 legmagasabb pontszámot elérő versenyző részt vehet egy <b>élményvezetési nap</b>on. *
                                </Text>
                            </li>
                            <li>
                                <Text variant="copy1">
                                    A legjobbnak bizonyuló hallgatók <b>meghívás</b>t kaphatnak <b>egy formabontó szakmai napra</b>,
                                    ahol bepillantást nyerhetnek az Audi Hungaria gyártósorainak működésébe, sőt,
                                    <b> 5 egyedi szakterületi látogatás</b> is gazdára talál majd.
                                </Text>
                            </li>
                            <li>
                                <Text variant="copy1">
                                    A legelhívatottabbak lehetőséget kapnak arra, hogy az övék legyen a nyitott <b>gyakornoki pozíciók</b> egyike.
                                </Text>
                            </li>
                        </ul>
                        <Text variant="copy1" spacing={["s", "xl", "xxs", "xxs"]}>
                            <b>Legfontosabb információk:</b>
                        </Text>
                        <Text variant="copy1" spacing={["s", "xl", "xxs", "xxs"]}>
                            <i>Online verseny:</i>
                        </Text>
                        <ul>
                            <li>
                                <Text variant="copy1">
                                    <u>Regisztráció határideje:</u> október 5. 16:00
                                </Text>
                            </li>
                            <li>
                                <Text variant="copy1">
                                    <u>Feladatsor megkezdésének ideje:</u> október 5. 16:00 – október 9. 24:00
                                </Text>
                            </li>
                            <li>
                                <Text variant="copy1">
                                    <u>Kitöltési idő:</u> maximum 60 perc
                                </Text>
                            </li>
                            <li>
                                <Text variant="copy1">
                                    <u>Jelleg:</u> online tesztfeladatsor
                                </Text>
                            </li>
                            <li>
                                <Text variant="copy1">
                                    <u>Mire készülj:</u> logikai teszt jellegű feladatok, alap mérnöki ismereteket felmérő feladatok
                                </Text>
                            </li>
                            <li>
                                <Text variant="copy1">
                                    <u>Bejelentkezés:</u> a regisztráció során megadott felhasználói adatokkal
                                </Text>
                            </li>
                        </ul>
                        <Text variant="copy1" spacing={["s", "xl", "xxs", "xxs"]}>
                            Ne habozz, regisztrálj mielőbb és alapozd meg szakmai jövődet az Audi Hungariánál!
                        </Text>
                        <Text variant="copy2" spacing={["s", "xl", "xxs", "xxs"]}>
                            <a href={"/data/2023/Audi_Plantech_Challenge_Dokumentumai_2023.pdf"} target={"_blank"} rel={"noreferrer"}>
                                *Részvételi feltételek és nyereményjáték szabályzat</a>
                        </Text>
                        <br/><br/>
                        <iframe
                            className="video"
                            src="https://www.youtube.com/embed/_3cBRSZ-Rrg"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                    </LayoutItem>
                </Layout>
            </section>
            <BackdropSection className="bottom" side={"right"} slider={"none"} background={award_background}>
                <Text variant="order3" className={"white"} spacing={["s", "xl", "xxs", "xxs"]}>
                    Verseny díjazása
                </Text>
                <Text variant="copy1" className={"white"} spacing={["s", "xl", "xxs", "xxs"]}>
                    A verseny feladatsort kitöltők között kisorsolunk <b>3 db 1 éves Netflix előfizetés</b>t.
                </Text>
                <Text variant="copy1" className={"white"} spacing={["s", "xl", "xxs", "xxs"]}>
                    Az 5 legmagasabb pontszámot elérő versenyző egy izgalmas <b>élményvezetési nap</b>pal lesz gazdagabb.
                </Text>
                <Text variant="copy1" className={"white"} spacing={["s", "xl", "xxs", "xxs"]}>
                    Az online verseny során legjobbnak bizonyuló hallgatók <b>meghívás</b>t kaphatnak egy <b>formabontó szakmai napra</b>,
                    ahol <b> 5 egyedi szakterületi látogatás</b> is gazdára talál majd.
                </Text>
            </BackdropSection>
            <section className="rounds">
                <Layout justify="center">
                    <Text variant="order2" spacing={["xl", "xxxl"]}>Verseny menete</Text>
                </Layout>
                <Layout justify="center" direction={{xs: "column", m: "row"}} align="center">
                    <ThemeProvider theme={audiDarkTheme}>
                        <div className="round">
                            <img className="round-backdrop" src={timing1} alt=""/>
                            <Layout className="container" justify="center" align="center" direction="column">
                                <Text variant="order2">Regisztráció</Text>
                                <Text variant="order4">határideje</Text>
                                <br/>
                                <Text variant="order2">2023.10.05</Text>
                                <Text variant="order3">16:00</Text>
                            </Layout>
                        </div>
                        <div className="round">
                            <img className="round-backdrop" src={timing2} alt=""/>
                            <Layout className="container" justify="center" align="center" direction="column">
                                <Text variant="order2">Online Verseny</Text>
                                <Text variant="order4">feladatkitöltés</Text>
                                <br/>
                                <Text variant="order2">2023.10.05</Text>
                                <Text variant="order3">16:00 - 10.09 24:00</Text>
                            </Layout>
                        </div>
                        <div className="round">
                            <img className="round-backdrop" src={timing3} alt=""/>
                            <Layout className="container" justify="center" align="center" direction="column">
                                <Text variant="order2">Szakmai nap</Text>
                                <Text variant="order4">Győr</Text>
                                <br/>
                                <Text variant="order2">2023.11.09</Text>
                                <Text variant="order3">09:00 - 16:00</Text>
                            </Layout>
                        </div>
                    </ThemeProvider>
                </Layout>
            </section>
            <Registration/>
            <FAQ/>
        </div>
    );
}