import React from "react";
import "./Contact.css";
import {Layout, Text} from "@audi/audi-ui-react";

export function Contact() {
    
    return (
        <Layout justify="center" direction="column" align="center" spacing={["xl"]}>
            <div className="contact">
                <Text variant="order1">Kapcsolat</Text>
                <br/>
                <Text variant="copy1">
                    Az Audi Plan@Tech Challenge versennyel kapcsolatban (regisztráció, adminisztráció, stb.) a következő e-mail címre várjuk a kérdéseket: <a href="mailto:support@plantechchallenge.hu">support@plantechchallenge.hu</a><br/>
                    <br/>
                    <b>Az elsődleges kommunikációs csatornánk az e-mail.</b><br/>
                    <br/>
                    A verseny szervezője:<br/>
                    Audi Hungária Zrt.<br/>
                    <a href="https://audi.hu" target="_blank" rel="noreferrer">audi.hu</a><br/>
                    Facebook: <a href="https://www.facebook.com/audikarrier/" target="_blank" rel="noreferrer">https://www.facebook.com/audikarrier/</a>
                </Text>
            </div>
        </Layout>
    );
}