import React, {useEffect, useState} from "react";
import "./GameHeader.css";
import {Layout, LayoutItem, Pagination} from "@audi/audi-ui-react";
import {Timer} from "../../../Components/Timer/Timer";

export function GameHeader({count, page, changePage, endTime, onEnded}) {
    const [time, setTime] = useState(0);
    const [ended, setEnded] = useState(false);
    
    const handleEnd = () => {
        setEnded(true);
        onEnded();
    };
    
    useEffect(() => {
        if (ended)
            return;
        
        const handle = setTimeout(() => {
            const newTime = (endTime - (Date.now() + (new Date().getTimezoneOffset() + 120) * 60000)) / 1000;
            if (newTime <= 0) {
                handleEnd();
            }
            setTime(newTime);
        }, 100);
        
        return () => {
            clearTimeout(handle);
        };
    });
    
    return (
        <Layout className="game-header" direction={{xs: "column", m: "row"}} justify="end">
            <LayoutItem align="center" justify="starts">
                <Pagination
                    count={count}
                    page={page}
                    onChange={changePage}/>
            </LayoutItem>
            <LayoutItem grow="1"/>
            <LayoutItem align="center">
                <Timer time={time}/>
            </LayoutItem>
        </Layout>
    );
}