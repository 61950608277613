import React, {useEffect, useState} from "react";
import "./Header.css";
import {Button, ButtonGroup, Layout, LayoutItem} from "@audi/audi-ui-react";
import {ReactComponent as Logo} from "./audi-logo.svg";
import {Link} from "react-router-dom";
import {Navigation, NavigationBurger} from "./Navigation/Navigation.jsx";
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../redux/modal";
import userIcon from "./user-icon.svg";
import {useHistory} from "react-router";
import {api} from "../../axios";
import {handleResponse} from "../../common/utils";

const handleLogin = dispatch => () => {
    dispatch(setModal({name: "login", data: {test: 1}}));
};

const handleRegistration = history => () => {
    history.push("/#regisztracio");
};

const handleDashboard = history => () => {
    history.push("/dashboard");
};

const handleLogout = dispatch => () => {
    api.delete("/user")
        .then(res => handleResponse(dispatch, res));
};

function UserBurger({firstname}) {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const [open, setOpen] = useState(false);
    
    return (
        <div className={`user-burger ${open ? "open" : ""}`}>
            <div className="profile-icon" onClick={() => {
                document.body.style.overflow = open ? "auto" : "hidden";
                setOpen(!open);
            }}>
                <div className="head">
                </div>
                <div className="body">
                </div>
            </div>
            <div className={`dropdown ${open ? "open" : ""}`}>
                <Layout className="content" direction="column">
                    {firstname === "" ?
                        (
                            <ButtonGroup variant="block-buttons">
                                <Button
                                    variant="primary"
                                    size="small"
                                    onClick={() => {
                                        document.body.style.overflow = "auto";
                                        setOpen(false);
                                        handleLogin(dispatch)();
                                    }}>
                                    Belépés
                                </Button>
                                <Button
                                    className="registration-button"
                                    variant="secondary"
                                    size="small"
                                    onClick={() => {
                                        document.body.style.overflow = "auto";
                                        setOpen(false);
                                        handleRegistration(history)();
                                    }}>
                                    Regisztráció
                                </Button>
                            </ButtonGroup>
                        ) : (
                            <ButtonGroup variant="block-buttons">
                                <Button
                                    className="icon-button"
                                    variant="secondary"
                                    size="small"
                                    onClick={() => {
                                        document.body.style.overflow = "auto";
                                        setOpen(false);
                                        handleDashboard(history)();
                                    }}
                                    icon={<img src={userIcon} alt={"user"}/>}>
                                    {firstname}
                                </Button>
                                <Button
                                    className="logout-button"
                                    variant="primary"
                                    size="small"
                                    onClick={() => {
                                        document.body.style.overflow = "auto";
                                        setOpen(false);
                                        handleLogout(dispatch)();
                                    }}>
                                    Kilépés
                                </Button>
                            </ButtonGroup>
                        )}
                </Layout>
            </div>
        </div>
    );
}

export function HeaderMobile({firstname}) {
    return (
        <header className="header mobile">
            <Layout className="container" justify="between" align="center">
                <NavigationBurger/>
                <Link to="/">
                    <Logo className="audi-logo" viewBox="0 0 196 68"/>
                </Link>
                <UserBurger firstname={firstname}/>
            </Layout>
        </header>
    );
}

export function HeaderComputer({firstname}) {
    const dispatch = useDispatch();
    const history = useHistory();
    
    return (
        <header className="header computer">
            <Layout justify="end" align="center">
                <Link to="/">
                    <Logo className="audi-logo" viewBox="0 0 196 68"/>
                </Link>
                <LayoutItem grow="1"/>
                <Navigation/>
                {firstname === "" ?
                    (
                        <ButtonGroup variant="block-buttons" spacing={["s", "xxs", "s", "s"]}>
                            <Button
                                spacing={["xxs", "m", "s", "m"]}
                                variant="primary"
                                size="small"
                                onClick={handleLogin(dispatch)}>
                                Belépés
                            </Button>
                            <Button
                                spacing={["xxs", "m", "s", "s"]}
                                variant="secondary"
                                size="small"
                                onClick={handleRegistration(history)}>
                                Regisztráció
                            </Button>
                        </ButtonGroup>
                    ) : (
                        <ButtonGroup variant="block-buttons" spacing={["s", "m", "s", "s"]}>
                            <Button
                                spacing={["xxs", "m", "s", "m"]}
                                className="icon-button"
                                variant="secondary"
                                size="small"
                                onClick={handleDashboard(history)}
                                icon={<img src={userIcon} alt={"user"}/>}>
                                {firstname}
                            </Button>
                            <Button
                                spacing={["xxs", "s", "s", "s"]}
                                variant="primary"
                                size="small"
                                onClick={handleLogout(dispatch)}>
                                Kilépés
                            </Button>
                        </ButtonGroup>
                    )}
            </Layout>
        </header>
    );
}

export function Header() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        const listener = () => {
            setScreenWidth(window.innerWidth);
        };
        
        window.addEventListener("resize", listener);
        
        return () => {
            window.removeEventListener("resize", listener);
        };
    }, [setScreenWidth]);
    
    const {firstname} = useSelector(state => state.user);
    if (screenWidth < 1250) {
        return (
            <HeaderMobile firstname={firstname}/>
        );
    } else {
        return (
            <HeaderComputer firstname={firstname}/>
        );
    }
}