import React, {useEffect, useRef, useState} from "react";
import "./FAQ.css";
import {Divider, Layout, LayoutItem, Text} from "@audi/audi-ui-react";

function FAQQuestion({question, children}) {
    const [open, setOpen] = useState(false);
    const [margin, setMargin] = useState(1);
    const answerRef = useRef();
    
    window.addEventListener("resize", () => {
        if (answerRef.current)
            setMargin(open ? 0 : -answerRef.current.offsetHeight);
    });
    
    useEffect(() => {
        if (margin > 0)
            setMargin(open ? 0 : -answerRef.current.offsetHeight);
    }, [margin, setMargin, open]);
    
    const handleClick = () => {
        setOpen(!open);
        setMargin(open ? -answerRef.current.offsetHeight : 0);
    };
    
    return (
        <div className="question">
            <div className="clickHandler" onClick={handleClick}>
                <Layout className="question-header" direction="row" justify="center" align="center">
                    <Text variant="copy1" spacing={["s", "xxs", "s", "l"]}>
                        <b>
                            {question}
                        </b>
                    </Text>
                    <LayoutItem grow="1"/>
                    <svg className={`open-arrow ${open ? "rotated" : ""}`} xmlns="http://www.w3.org/2000/svg"
                         id="audi-down-small"
                         viewBox="0 0 24 24" version="1.1">
                        <polyline
                            transform="translate(11.794000, 12.273500) rotate(90.000000) translate(-11.794000, -12.273500) "
                            points="9.2245 18.0675 8.5205 17.3575 13.6475 12.2705 8.5255 7.1895 9.2295 6.4795 15.0675 12.2705 9.2245 18.0675"/>
                    </svg>
                </Layout>
            </div>
            <Divider spacing={["s", "xxs", "s", "s"]}/>
            <div ref={answerRef} className={`answer ${open ? "" : "closed"}`} style={{marginTop: `${margin}px`}}>
                <Text variant="copy1" spacing={["s", "xxs", "s", "s"]}>
                    {children}
                </Text>
                <Divider spacing={["s", "xxs", "s", "s"]}/>
            </div>
        </div>
    );
    
}

export function FAQ() {
    
    return (
        <section className="faq">
            <Text variant="order3" spacing={["xxs"]}>
                Gyakran ismételt kérdések
            </Text>
            <br/>
            <Text variant="order4" spacing={["xs"]}>
                Regisztráció
            </Text>
            <Divider spacing={["s", "xxs", "s", "s"]}/>
            <FAQQuestion question="Mely szakokról jelentkezhetek?">
                Elsősorban aktív, nappali tagozatos mérnök hallgatók jelentkezését várjuk a következő szakokról:
                járműmérnök, gépészmérnök, villamosmérnök, mechatronikai mérnök.
            </FAQQuestion>
            <FAQQuestion question="Meddig tudok regisztrálni a Plan@Tech szakmai versenyre?">
                Az online fordulóra 2023. október 5. 16:00 óráig tudtok regisztrálni a Regisztráció menüpont alatt pár
                személyes adat megadásával és a részvételi, illetve az adatvédelmi tájékoztató elfogadásával.
            </FAQQuestion>
            <FAQQuestion question="Díjmentes a versenyre történő regisztráció?">
                Igen, teljes mértékben díjmentes a regisztráció.
            </FAQQuestion>
            <FAQQuestion question="Mik a teendőim a regisztrációt követően?">
                A sikeres regisztráció után visszaigazoló e-mailt küldünk a megadott e-mail címre,
                amiben találni fogsz egy megerősítő linket. Erre kattintva tudod megerősíteni és
                véglegesíteni a regisztrációdat, majd ezek után belépni az oldalon.
            </FAQQuestion>
            <FAQQuestion question="Mit tegyek, ha nem kapok visszaigazoló e-mailt a regisztrációmról?">
                Ellenőrizd, hogy helyesen adtad-e meg az e-mail címed (újbóli regisztrációnál nem fog
                engedni regisztrálni a rendszer), illetve ellenőrizd a spam mappát a fiókodban.
                Ha 10 percen belül nem érkezik meg, akkor vedd fel velünk a kapcsolatot a <a
                href={"mailto:support@plantechchallenge.hu"}>support@plantechchallenge.hu</a> e-mail címen.
            </FAQQuestion>
            <FAQQuestion question="Csapatban vagy csak egyénileg vehetek részt a versenyen?">
                A verseny egy egyéni megmérettetés.
            </FAQQuestion>
            <Text variant="order4" spacing={["xs"]}>
                Online verseny
            </Text>
            <Divider spacing={["s", "xxs", "s", "s"]}/>
            <FAQQuestion question="Szükséges-e kép és hang felvételére alkalmas eszközzel bejelentkeznem?">
                Az online verseny során nem szükséges kép és hangfelvételére alkalmas eszközzel bejelentkezni.
            </FAQQuestion>
            <FAQQuestion question="Mikortól elérhető az online verseny felülete?">
                Az online verseny felülete 2023. október 5. 16:00-tól elérhető.
            </FAQQuestion>
            <FAQQuestion question="Mennyi idő áll rendelkezésemre az online feladatok kitöltéséhez?">
                A feladatok megoldására 60 perc áll rendelkezésre és a technikai okokból elveszített
                idő nem bepótolható.
            </FAQQuestion>
            <FAQQuestion question="A kérdések szabadon választható sorrendben megoldhatóak?">
                Igen, a kérdéseket bármilyen sorrendben meg lehet oldani. Előre vagy visszalépési lehetőség biztosított.
            </FAQQuestion>
            <FAQQuestion question="Milyen jellegű feladatok várhatóak?">
                Logikai, teszt jellegű és mérnöki ismeretek segítségével megoldható feladatok.
            </FAQQuestion>
            <FAQQuestion question="Regisztráltam, de még sem tudok részt venni az online fordulón. Mi a teendőm?">
                Technikai probléma esetén vedd fel velünk a kapcsolatot a <a
                href={"mailto:support@plantechchallenge.hu"}>support@plantechchallenge.hu</a>-n keresztül.
            </FAQQuestion>
            <FAQQuestion question="Mikor és milyen formában kapok visszajelzést a versenyen elért eredményemről?">
                A verseny lebonyolítását követő két héten belül a jutalmazottakat értesítjük.
            </FAQQuestion>
            <FAQQuestion question="Milyen díjak várnak rám?">
                1-5. helyezés: Audi élményvezetés / vezetéstechnikai tréning.
                Ezen felül 3 db 1 évre szóló Netflix Prémium előfizetés is kisorsolásra kerül azok között,
                akik a feladatsor elérhető maximális pontszámának legalább 20%-át elérték.
                Amennyiben a nyereményjátékban nem szeretnél reszt venni, jelezd a  <a
                href={"mailto:support@plantechchallenge.hu"}>support@plantechchallenge.hu</a> címen.
            </FAQQuestion>
        </section>
    );
}