import React, { useState, useEffect } from "react";
import {Checkbox} from "@audi/audi-ui-react";

export default function ChecklistItem({ label, activeItemsCount, setActiveItemsCount, onChange, checked }) {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (!isActive) {
            setActiveItemsCount((prevCount) => {
                if (prevCount !== 0) return prevCount - 1;
                return prevCount;
            })
        }

        if (isActive) {
            setActiveItemsCount((prevCount) => prevCount + 1);
        }

    }, [isActive, setActiveItemsCount]);

    return (
        <>
            <Checkbox
                className={"intern-interest"}
                type="checkbox"
                checked={checked}
                disabled={activeItemsCount === 3 && !isActive}
                onChange={onChange}>
                {label}
            </Checkbox>

        </>


    );
};