import React, {useEffect, useState} from "react";
import "./Alert.css";

import {Text} from "@audi/audi-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {clearAlert} from "../../redux/alert";

export const AlertTypes = {
    SUCCESS: "success",
    DANGER: "danger",
    INFO: "info",
};

export function Alert() {
    const dispatch = useDispatch();
    const [active, setActive] = useState(false);
    const [handle, setHandle] = useState(-1);
    
    const {type, msg} = useSelector(state => state.alert);
    
    if (type === null && active) {
        setActive(false);
    } else if (type !== null && !active) {
        setHandle(setTimeout(() => {
            dispatch(clearAlert());
        }, 5 * 1000));
        setActive(true);
    }
    
    const handleClick = () => {
        dispatch(clearAlert());
    };
    
    useEffect(() => {
        return () => {
            if (handle !== -1) {
                clearTimeout(handle);
                setHandle(-1);
            }
        };
    }, [handle]);
    
    return (
        <div
            className={`alert ${type} ${type === null ? "inactive" : ""}`}
            onClick={handleClick}>
            <Text as="span" variant="order4"><span dangerouslySetInnerHTML={{__html: msg}} /></Text>
        </div>
    );
}