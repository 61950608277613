import {createSlice} from "@reduxjs/toolkit";

export const modalSlice = createSlice({
    name: "modal",
    initialState: {
        name: null,
        data: null,
    },
    reducers: {
        setModal: (state, action) => {
            state.name = action.payload.name;
            state.data = action.payload.data ?? {};
        },
        clearModal: state => {
            state.name = null;
        },
    },
});

export const {setModal, clearModal} = modalSlice.actions;

export default modalSlice.reducer;