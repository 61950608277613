import React from "react";
import "./Intern.css";
import {Layout, Text} from "@audi/audi-ui-react";

export function Intern() {

    return (
        <Layout justify="center" direction="column" align="center" spacing={["xl"]}>
            <div className="intern">
                <Text variant="order1">Gyakornoki lehetőségek</Text>
            </div>
        </Layout>
    );
}