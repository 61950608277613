import React, {useState} from "react";
import "./Dashboard.css";
import {audiDarkTheme, Button, Layout, LayoutItem, Loader, Text, TextField, ThemeProvider} from "@audi/audi-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {Countdown} from "./Countdown/Countdown";
import {setModal} from "../../redux/modal";
import {api, testHash} from "../../axios";
import {handleResponse} from "../../common/utils";
import qs from "qs";
import {setupUser} from "../../redux/user";

export function Dashboard() {
    const dispatch = useDispatch();
    const {serverDateTime, gameStartDateTime, fetching, video} = useSelector(state => state.user);
    const serverDate = new Date(serverDateTime);
    const gameStartDate = new Date(gameStartDateTime);
    const difference = Math.floor((gameStartDate - serverDate) / 1000);
    
    const [videoURL, setVideoURL] = useState("");
    
    const startHandler = () => {
        dispatch(setModal({name: "startGame"}));
    };
    
    const handleSendURL = () => {
        api.post("/user/upload", qs.stringify({url: videoURL, testHash}))
            .then(res => {
                handleResponse(dispatch, res);
                if (res.data.isValid) {
                    dispatch(setupUser(res.data.data));
                }
            });
        setVideoURL("");
    };
    
    return (
        <div className="dashboard">
            <Layout className="section countdown" justify="center">
                <LayoutItem className="centered" align="center">
                    <Layout direction="column">
                        {(fetching || difference > 0) ?
                            <>
                                <Text spaceStackStart="s" variant="order3" className="title">Verseny kezdetéig</Text>
                                <Button spaceStackStart="s" className="timer" variant="secondary" disabled>
                                    {fetching ?
                                        <Loader size="small" className="loader"/> :
                                        <Countdown remainingSeconds={difference}/>}
                                </Button>
                            </> : <>
                                <Text spaceStackStart="s" variant="order3" className="title">A verseny elkezdődött</Text>
                            </>
                        }
                        <ThemeProvider theme={audiDarkTheme}>
                            <Button spaceStackStart="s" variant="primary" onClick={startHandler}>Start!</Button>
                        </ThemeProvider>
                    </Layout>
                </LayoutItem>
            </Layout>
            {/*<Layout className="upload" direction={{xs: "column", m: "row"}} justify="center">*/}
            {/*    <LayoutItem align="center" className="part left" spacing={["xl"]}>*/}
            {/*        <Text variant="order2">Projektfeladat plusz pontért!</Text>*/}
            {/*        <br/>*/}
            {/*        <Text variant="copy1">*/}
            {/*            Válaszolj maximum 1 perces videóban az alábbi kérdésre:<br/>*/}
            {/*            <br/>*/}
            {/*            <b className="mobility">Szerinted melyik a legfenntarthatóbb személygépjármű-hajtás? Miért?</b><br/>*/}
            {/*            <br/>*/}
            {/*            A bemutatás módját és a videó jellegét rád bízzuk! Légy kreatív!<br/>*/}
            {/*            A videódat töltsd fel a Google Drive-ra vagy a Microsoft OneDrive-ra, és a <strong>megosztást állítsd be*/}
            {/*            a következő e-mail címre:</strong> <a*/}
            {/*            href="mailto:support@plantechchallenge.hu">support@plantechchallenge.hu</a><br/>*/}
            {/*            A megosztási linket légyszíves itt a felületen is add meg.<br/>*/}
            {/*            <br/>*/}
            {/*            Beadási határidő: 10.11 éjfél<br/>*/}
            {/*        </Text>*/}
            {/*    </LayoutItem>*/}
            {/*    <LayoutItem align="center" className="part right" spacing={["xl"]}>*/}
            {/*        <TextField label="Video URL" inputId="video-url" maxLength={200} name="video-url" value={videoURL}*/}
            {/*                   onChange={e => setVideoURL(e.target.value)} required/>*/}
            {/*        <br/>*/}
            {/*        <Button variant="primary" onClick={handleSendURL}>Beküldés</Button>*/}
            {/*        <br/>*/}
            {/*        <br/>*/}
            {/*        {!fetching && video.url !== null ?*/}
            {/*            <Text variant="copy2">Beküldve {video.dateTime}: <a href={video.url} target="_blank"*/}
            {/*                                                                rel="noreferrer">Megnyitás</a></Text> : null}*/}
            {/*    </LayoutItem>*/}
            {/*</Layout>*/}
            {/*<Layout className="survey" direction={{xs: "column", m: "row"}} justify="center">*/}
            {/*    <LayoutItem align="center" className="part left" spacing={["xl"]}>*/}
            {/*        <Text variant="order2">Kérdőív</Text>*/}
            {/*        <br/>*/}
            {/*        <Text variant="copy1">*/}
            {/*            Kérünk, szánj 3 percet az alábbi kérdőív kitöltésére, mely abban segít minket, hogy jobban megismerjük a gyakornoki helyekkel szemben támasztott elvárásaid.<br/>*/}


            {/*        </Text>*/}
            {/*        <br/>*/}
            {/*        <br/>*/}
            {/*        <Button variant="secondary"*/}
            {/*                onClick={() => window.open('https://form.typeform.com/to/xw5WGo1R', '_blank')}>Kérdőív kitöltése</Button>*/}
            {/*        <br/>*/}
            {/*        <br/>*/}
            {/*    </LayoutItem>*/}
            {/*</Layout>*/}
        </div>
    );
}