import React, { useState } from "react";
import "./ProfessionalDay.css";
import {Button, Layout, Text, TextField, RadioButton} from "@audi/audi-ui-react";
import { api } from "../../axios";
import qs from "qs";
import { handleResponse } from "../../common/utils";
import { useDispatch } from "react-redux";

export function ProfessionalDay() {
    const dispatch = useDispatch();

    const [fullname, setFullname] = useState("");
    const [phone, setPhone] = useState("");

    const [fullnameError, setFullnameError] = useState("");
    const [phoneError, setPhoneError] = useState("");

    const [travelTo, setTravelTo] = useState("");
    const [travelFrom, setTravelFrom] = useState("");

    const optionsTo = [
        "Magam oldom meg az utazást",
        "Budapest Kelenföldön szállok fel a buszra",
        "Győr buszpályaudvaron szállok fel a buszra",
        "A Széchenyi István Egyetemnél (Mobilis) szállok fel a buszra"
    ];

    const optionsFrom = [
        "Magam oldom meg az utazást",
        "Budapest Kelenföldre szeretnék menni busszal",
        "Győr buszpályaudvarra szeretnék menni busszal",
        "A Széchenyi István Egyetemhez (Mobilis) szeretnék menni busszal"
    ];

    const handleSubmit = () => {
        let errored = false;

        if (fullname.length === 0) {
            setFullnameError("A név megadása kötelező!");
            errored = true;
        } else {
            setFullnameError("");
        }

        if (phone.length === 0) {
            setPhoneError("A telefonszám megadása kötelező!");
            errored = true;
        } else {
            setPhoneError("");
        }

        const selectedTravelTo = optionsTo[travelTo];
        const selectedTravelFrom = optionsFrom[travelFrom];

        api.post("/professionalDay", qs.stringify({
            fullname,
            phone,
            travelTo: selectedTravelTo,
            travelFrom: selectedTravelFrom,
        }))
            .then(res => {
                if (res.data.isValid) {
                    setFullname("");
                    setPhone("");
                    setTravelTo("");
                    setTravelFrom("");
                    setFullnameError("");
                    setPhoneError("");
                }
                handleResponse(dispatch, res)
            });
    };

    const handleTravelToChange = (index) => {
        setTravelTo(index);
    };

    const handleTravelFromChange = (index) => {
        setTravelFrom(index);
    };

    return (
        <Layout justify="center" direction="column" align="center" spacing={["xl"]}>
            <div className="manufactoring">
                <section>
                    <Text variant="order1">Szakmai nap visszajelzés</Text>
                    <br/>
                    <Text variant="copy1">
                        Köszönjük, hogy elfogadtad a meghívásunkat a szakmai napunkra, ahol bepillantást
                        nyerhetsz az Audi Hungaria gyártósorainak működésébe is! Kérjük, az alábbi mezőket töltsd ki
                        2023.november 1-ig, hogy ezzel segítsd a szakmai napunk szervezését!<br/>
                        Bővebb információk a szakmai napról a visszajelző űrlap alatt.<br/>
                    </Text>
                    <TextField
                        value={fullname}
                        onChange={e => setFullname(e.target.value)}
                        inputId="registration-fullname"
                        label="Teljes név *"
                        required
                        invalid={fullnameError.length > 0}
                        validationMessage={fullnameError}/>
                    <TextField
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        inputId="registration-phone"
                        label="Telefonszám *"
                        required
                        invalid={phoneError.length > 0}
                        validationMessage={phoneError}
                    />
                    <br/>
                    <Text variant="copy1">Hogyan fogsz eljutni a rendezvényre?</Text>
                    {["Magam oldom meg az utazást", "Budapest Kelenföldön szállok fel a buszra", "Győr buszpályaudvaron szállok fel a buszra", "A Széchenyi István Egyetemnél (Mobilis) szállok fel a buszra"].map((option, index) => (
                        <RadioButton
                            key={index}
                            spacing={["s"]}
                            checked={travelTo === index}
                            onChange={() => handleTravelToChange(index)}
                            inputId={'0'}>
                            {option}
                        </RadioButton>
                    ))}
                    <br/>
                    <Text variant="copy1">A rendezvényről hogyan szeretnél hazautazni?</Text>
                    {["Magam oldom meg az utazást", "Budapest Kelenföldre szeretnék menni busszal", "Győr buszpályaudvarra szeretnék menni busszal", "A Széchenyi István Egyetemhez (Mobilis) szeretnék menni busszal"].map((option, index) => (
                        <RadioButton
                            key={index}
                            spacing={["s"]}
                            checked={travelFrom === index}
                            onChange={() => handleTravelFromChange(index)}
                            inputId={'1'}>
                            {option}
                        </RadioButton>
                    ))}
                    <br/>
                    <Button variant="primary" onClick={handleSubmit}>Küldés</Button>
                    <br/>
                    <br/>
                    <br/>
                    <Text variant="order1">Információk a szakmai napról</Text>
                    <br/>
                    <br/>
                    <Text variant="copy1">
                        <strong>A szakmai nap időpontja:</strong> 2023.11.09. 09:00-16:00 óra<br/>
                        <strong>Helyszín:</strong> 9027 Győr, Audi Hungária út 1. (korábban Kardán út 1.) Pipacs rendezvényközpont<br/>
                        <strong>Programelemek:</strong>
                        <ul>
                            <li>exkluzív területbemutatás mérnökeink vezetésével</li>
                            <li>csoportos projektfeladatok</li>
                            <li>interjúlehetőség</li>
                        </ul>
                        <strong>Felkészülés:</strong> nem szükséges, Rád vagyunk kíváncsiak, a tudásodra és a kreativitásodra.<br/>
                        <strong>Dresscode:</strong> az öltönyt és nyakkendőt, illetve kiskosztümöt bátran hagyd otthon, de a melegítőt és a
                        bakancsot is. Nincs elvárt dresscode, <strong>számodra kényelmes, viszont az alkalomhoz illő ruhában érkezz!</strong>
                        <br/>
                        <br/>
                        A rendezvényen fotók fognak készülni.
                        <br/>
                        <br/>
                        A nap folyamán lehetőséged lesz visszajelzést kapni a csapatfeladatok során nyújtott teljesítményedről, továbbá megismerheted Gyakornoki Programunkat és nyitott pozícióinkat is.
                        <br/>
                        <br/>
                        <strong>A helyszínre utazásról részletesen</strong> (ha Shuttle buszt választottál, kérlek, minden helyszín esetén 10 - 15 perccel az indulás előtt érkezz)!
                        <br/>
                        <br/>
                    </Text>
                    <Text variant="order4">Budapest, Kelenföld – Audi Hungaria (Audi Shuttle I.)</Text>
                    <Text variant="copy1">
                        <strong>Indulás időpontja:</strong> 2023.11.09. 6:45<br/>
                        <strong>Indulás helye:</strong> <a href={"https://www.google.hu/maps/place/Budapest,+Etele+%C3%BAt+%26+Somogyi+%C3%BAt,+1119/@47.4650102,19.0223605,291m/data=!3m1!1e3!4m5!3m4!1s0x4741ddc1b17f9739:0x7d55317a06f88ccd!8m2!3d47.4649703!4d19.0235635?hl=hu"} target={"_blank"}>Etele út - Somogyi út sarok</a><br/><br/>
                        <img style={{width: "100%"}} src={require("../ProfessionalDay/image_1.jpg").default}  alt={"Első kép"}/>
                        <br/>
                        <br/>
                    </Text>
                    <Text variant="order4">Győr, Széchenyi István Egyetem – Audi Hungaria (Audi Shuttle II.)</Text>
                    <Text variant="copy1">
                        <strong>Indulás időpontja:</strong> 2023.11.09. 7:30<br/>
                        <strong>Indulás helye:</strong> <a href={"https://www.google.hu/maps/place/Mobilis+Interakt%C3%ADv+%C3%89lm%C3%A9nyk%C3%B6zpont/@47.695289,17.6230848,631m/data=!3m1!1e3!4m14!1m7!3m6!1s0x476b95602682fc7b:0xcbd9194cc82b4c5a!2zTW9iaWxpcyBJbnRlcmFrdMOtdiDDiWxtw6lueWvDtnpwb250!8m2!3d47.695289!4d17.6256597!16s%2Fg%2F1pp2tzjs6!3m5!1s0x476b95602682fc7b:0xcbd9194cc82b4c5a!8m2!3d47.695289!4d17.6256597!16s%2Fg%2F1pp2tzjs6?hl=hu&entry=ttu"} target={"_blank"}>Széchenyi István Egyetem, Mobilis</a><br/><br/>
                        <img style={{width: "100%"}} src={require("../ProfessionalDay/image_3.jpg").default}  alt={"Első kép"}/>
                        <br/>
                        <br/>
                    </Text>
                    <Text variant="order4">Győr, Buszpályaudvar – Audi Hungaria (Audi Shuttle III.)</Text>
                    <Text variant="copy1">
                        <strong>Indulás időpontja:</strong> 2023.11.09. 7:50<br/>
                        <strong>Indulás helye:</strong> <a href={"https://www.google.hu/maps/place/Gy%C5%91r,+Eszperant%C3%B3+%C3%BAt,+aut%C3%B3busz-%C3%A1llom%C3%A1s/@47.6817441,17.63558,108m/data=!3m1!1e3!4m8!1m2!2m1!1zdmlkw6lraSBww6FseWF1ZHZhciBnecWRcg!3m4!1s0x476bbfee29d8ce77:0xc9c4c2a7a6fa2daf!8m2!3d47.681929!4d17.63618?hl=hu"} target={"_blank"}>Vidéki Pályaudvar, Eszperantó út</a><br/><br/>
                        <img style={{width: "100%"}} src={require("../ProfessionalDay/image_2.jpg").default}  alt={"Első kép"}/>
                        <br/>
                        <br/>
                    </Text>
                    <Text variant="order4">Ha autóval, egyénileg érkezel</Text>
                    <Text variant="copy1">
                        Haladj végig egyenesen a Kardán úton (1.) → hajts el a Főporta mellett (2.) → bal oldalon látni fogod a Pipacs Rendezvényközpontot (3.) → fordulj balra és megérkezel a parkolóhoz (4.)<br/>
                        <strong>Érkezés időpontja:</strong> 2023.11.09. 8:20-8:30
                        <br/><br/>
                        <img style={{width: "100%"}} src={require("../ProfessionalDay/image_4.jpg").default}  alt={"Első kép"}/>
                        <br/>
                        <br/>
                    </Text>
                    <Text variant="copy1">
                        Várunk szeretettel a 2023. évi szakmai napunkra!<br/>
                        Mi már nagyon készülünk! :)
                        <br/><br/>
                        Üdvözlettel:<br/>A Plan@Tech szervezőcsapata
                    </Text>
                </section>
                <br/><br/>
                </div>

        </Layout>
    );
}