import React, {useEffect, useState} from "react";
import {NavigationBar, NavigationItem} from "@audi/audi-ui-react";
import {useHistory} from "react-router";
import "./Navigation.css";
import {Link} from "react-router-dom";

const items = [
    {page: "/", id: "nav-home", title: "2023 Plan@Tech Challenge"},
    {page: "/visszapillanto", id: "nav-description", title: "Visszapillantó"},
    {page: "/gyartastechnologia-tervezes", id: "nav-gyartastechnologia-tervezes", title: "Gyártástechnológia-tervezés"},
];

export function NavigationBurger() {
    const [open, setOpen] = useState(false);
    
    return (
        <div className={`navigation-burger ${open ? "open" : "closed"}`} onClick={() => {
            setOpen(!open);
            document.body.style.overflow = open ? "auto" : "hidden";
        }}>
            <span key={open ? "open1" : "closed1"} className="line top">
            </span>
            <span key={open ? "open2" : "closed2"} className="line middle">
            </span>
            <span key={open ? "open3" : "closed3"} className="line bottom">
            </span>
            <div className={`dropdown ${open ? "open" : ""}`}>
                <ul>
                    {
                        items.map(item => (
                            <Link to={item.page} key={item.id}>
                                <li className="menu-item">
                                    <a className={"menu-link"} href={item.page}>{item.title}</a>
                                </li>
                            </Link>
                        ))
                    }
                    <li className="menu-item">
                        <a href={"https://www.audi.hu/gyakorlat"} target={"_blank"} rel="noreferrer">Gyakornoki lehetőségek</a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export function Navigation() {
    const history = useHistory();
    const [selected, setSelected] = useState(
        items.find(item => item.page === window.location.pathname)?.id ?? null,
    );
    
    useEffect(() => {
        history.listen((location) => {
            setSelected(items.find(item => item.page === location.pathname)?.id ?? null);
        });
    }, [history]);
    
    const handleSelect = id => {
        const item = items.find(item => item.id === id);
        history.push(item.page);
    };
    
    return (
        <NavigationBar className={"navbar"} selected={selected} onSelect={handleSelect}>
            {
                items.map((item, index) => <NavigationItem key={index} {...item}>
                    <a className={"menu-link"} href={item.page}>{item.title}</a>
                </NavigationItem>)
            }
            <a className={"menu-link"} href={"https://www.audi.hu/gyakorlat"} target={"_blank"} rel="noreferrer">Gyakornoki lehetőségek</a>
        </NavigationBar>
    );
}