import {createSlice} from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        fetching: true,
        firstname: "",
        serverDateTime: null,
        gameStartDateTime: null,
        video: null,
        fetchEnded: 0,
    },
    reducers: {
        setupUser: (state, action) => {
            state.fetching = false;
            state.fetchEnded = Date.now();
            if (action.payload !== null) {
                state.firstname = action.payload.firstName ?? state.firstname;
                state.serverDateTime = action.payload.serverDateTime ?? state.serverDateTime;
                state.gameStartDateTime = action.payload.gameStartDateTime ?? state.gameStartDateTime;
                state.video = action.payload.video ?? state.video;
            } else {
                state.firstname = "";
            }
        },
    },
});

export const {setupUser} = userSlice.actions;

export default userSlice.reducer;